import { EditFilled, RestFilled } from "@ant-design/icons";
import {
  ConfigProvider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Switch,
  Tabs,
} from "antd";
import confirm from "antd/lib/modal/confirm";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import SearchBar from "../components/SearchBar";
import Spinner from "../components/Spinner";
import { CreateUser, GetUsers } from "../services/auth.service";
import { httpClient } from "../services/http.service";
import { updateInputv2 } from "../utilities";

var file;

const userRoles = [
  { value: "users.bulkImport", label: "Bulk Import Users" },
  { value: "users.create", label: "Create Users" },
  { value: "users.edit", label: "Edit Users" },
  { value: "users.list", label: "View Users" },
  { value: "scsrs.bulkImport", label: "Bulk Import SCSRs" },
  { value: "scsrs.create", label: "Create SCSRs" },
  { value: "scsrs.edit", label: "Edit SCSRs" },
  { value: "scsrs.list", label: "View SCSRs" },
  { value: "mines.bulkImport", label: "Bulk Import Mines" },
  { value: "mines.create", label: "Create Mines" },
  { value: "mines.edit", label: "Edit Mines" },
  { value: "mines.list", label: "View Mines" },
  { value: "rfq.create", label: "Create RFQs" },
  { value: "reports.view", label: "View Reports" },
 
]

const ImportUser = (props) => {
  const [isModalOpen, setisModalOpen] = useState(false);

  const [isLoading, setisLoading] = useState(false);

  const handleOpenModal = () => {
    setisModalOpen(true);
  };

  const [resultDetails, setresultDetails] = useState();

  // const handleUpload = () => {};

  const handleCancel = () => {
    setisModalOpen(false);
    setresultDetails([]);
  };

  function ImportBulkUsers(file) {
    let formData = new FormData();
    formData.append("users", file, file.name);

    setisLoading(true);

    httpClient
      .post("/api/v1/users/bulk", formData)
      .then((response) => {
        message.success(`Users imported successfully !`);
        props.reload();

        setisLoading(false);

      })
      .catch((error) => {
        message.error(`Failed to upload users`);
        setresultDetails(error?.response?.data || []);

        setisLoading(false);
      });
  }

  return (
    <>
      <Button
        onClick={handleOpenModal}
        size="sm"
        style={{ margin: 2 }}
        variant={props.color || "primary"}
      >
        {props.title || (
          <>
            {" "}
            <Plus /> New User
          </>
        )}
      </Button>
      <Modal
        width={600}
        footer={[
          <Button disabled={isLoading} onClick={() => ImportBulkUsers(file)}>Import Users</Button>,
        ]}
        title={props.title}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Input
            accept=".csv"
            type="file"
            onChange={(e) => {
              file = e.target.files[0];
              console.log(`File`, file);
            }}
          />

          {resultDetails?.map((resul) => (
            <p style={{ color: resul?.failed ? "red" : "green" }}>
              {resul?.user}, {resul.message}{" "}
            </p>
          ))}
        </Form>
      </Modal>
    </>
  );
};

const UpdateCreateUserV2 = (props) => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [userInformation, setuserInformation] = useState();

  const success = (msg) => {
    Modal.success({
      content: msg,
    });
  };

  const handleOk = () => { };
  const handleCancel = () => {
    setuserInformation({});
    setisModalOpen(false);
    if (props.reload) props.reload();
  };

  const [form] = Form.useForm();
  // const role = Form.useWatch('role', form);

  const loadUserDetails = (id) => {
    setisLoading(true);
    httpClient
      .get(`/api/v1/users/${id}`)
      .then((response) => {
        const { data } = response;
        setuserInformation({ ...data?.data });

        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const handleOpenModal = () => {
    setisModalOpen(true);
    if (props._id) loadUserDetails(props._id);
  };

  const onFinish = (values) => {
    console.log("Success:", values);

    setisLoading(true);

    if (props._id)
      httpClient
        .put(`/api/v1/users/${props._id}`, userInformation)
        .then((response) => {
          // const { data } = response;

          message.success("User details saved successfuly!");

          handleCancel();

          setisLoading(false);
        })
        .catch((err) => {
          message.error(err?.response?.data?.data || err);
        });
    else
      CreateUser(userInformation, (result) => {
        setisLoading(false);
        if (result?.isSuccess) {
          message.success(`Users imported successfully !`);

          if (result?.data?.plainPassword) {
            success(
              <p style={{ textAlign: "center" }}>
                {userInformation?.email}, passowrd:
                <h3 style={{ color: "green" }}>
                  {result?.data?.plainPassword}
                </h3>
              </p>
            );
          }
          handleCancel();
        } else
          notification.error({
            message: "User",
            description: result?.data,
            placement: "top",
          });
      });
  };

  return (
    <>
      <Button
        disabled={isLoading}
        onClick={handleOpenModal}
        size="sm"
        style={{ margin: 2 }}
        variant={props.color || "primary"}
      >
        {props.title || (
          <>
            {" "}
            <Plus /> New User
          </>
        )}
      </Button>
      <Modal
        width={500}
        footer={[
          <Button size="sm" onClick={() => onFinish()}>
            Save
          </Button>,
        ]}
        title={props.mainTitle}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          disabled={isLoading}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 20 }}
        >
          <ConfigProvider>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="User Information" key="1">
                <Form.Item label="Email">
                  <Input
                    disabled={props._id}
                    value={userInformation?.email}
                    onChange={(e) =>
                      updateInputv2(e.target.value, "email", setuserInformation)
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Name"
                  rules={[{ required: true, message: "Please input your name!" }]}
                >
                  <Input
                    onChange={(e) =>
                      updateInputv2(e.target.value, "name", setuserInformation)
                    }
                    value={userInformation?.name}
                  />
                </Form.Item>
                <Form.Item
                  label="Surname"
                  rules={[
                    { required: true, message: "Please input your surname!" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      updateInputv2(e.target.value, "surname", setuserInformation)
                    }
                    value={userInformation?.surname}
                  />
                </Form.Item>

                <Form.Item
                  label="User Role"
                  rules={[{ required: true, message: "Please select a role !" }]}
                >
                  <Select
                    value={userInformation?.role}
                    onChange={(e) => updateInputv2(e, "role", setuserInformation)}
                  >
                    <Select.Option value="Admin">Admin</Select.Option>
                    <Select.Option value="Inspector">Inspector</Select.Option>
                    <Select.Option value="Repairman">Repairman</Select.Option>
                    <Select.Option value="Mine">Mine</Select.Option>
                    <Select.Option value="OEM">OEM</Select.Option>
                  </Select>
                </Form.Item>
                {userInformation?.role === "Admin" &&
                  <Form.Item
                    label="Roles"
                    rules={[{ required: true, message: "Please select roles !" }]}
                  >

                    <Select
                      mode="tags"
                      value={userInformation?.roles}
                      onChange={(e) => updateInputv2(e, "roles", setuserInformation)}
                    >
                      {userRoles.map(role => <Select.Option value={role.value}>{role.label}</Select.Option>)}

                    </Select>
                  </Form.Item>}

                <Form.Item
                  label="Status"
                  rules={[{ required: true, message: "Please select a role !" }]}
                >
                  <Switch
                    checked={userInformation?.isEnable}
                    onChange={(e) =>
                      updateInputv2(e, "isEnable", setuserInformation)
                    }
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </Form.Item>

                   
                {userInformation?.role === "Mine" && <Form.Item
                  label="Mine Name"
                  name="mine"
                  rules={[{ required: true, message: 'Please select a mine !' }]}
                >
                  <Select
                    value={userInformation?.mine}
                    defaultValue={userInformation?.mine}
                    onChange={(e) => updateInputv2(e, "mine", setuserInformation)}>
                      
                    {props.mines?.map(mine => <Select.Option value={mine?._id}>{mine?.name}</Select.Option>)}

                  </Select>
                </Form.Item>
                }
              </Tabs.TabPane>
            </Tabs>
          </ConfigProvider>

        </Form>
      </Modal>
    </>
  );
};

const ResetPassword = (props) => {
  const handleClick = () => {
    confirm({
      title: "Password reset",
      content: <>Are your sure you want to reset {props.email} password ?</>,

      onOk() {
        httpClient
          .post("/api/v1/auth/passwordreset", {
            email: props.email,
          })
          .then((res) => {
            const { data } = res;
            Modal.success({
              title: `${props.email} password reseted !`,
              content: (
                <p>
                  New Password :{" "}
                  <h3 style={{ color: "green" }}>{data?.password}</h3>
                </p>
              ),
            });
          });
      },
    });
  };

  return (
    <>
      <Button
        title={`Reset password for ${props.email} !`}
        style={{ margin: "2px" }}
        size="sm"
        onClick={(e) => handleClick()}
      >
        <RestFilled />{" "}
      </Button>
    </>
  );
};

export default function Users() {
  const [users, setusers] = useState([]);
  const [mines, setmines] = useState([])

  const [isLoading, setisLoading] = useState(false);

  const loadUsers = () => {
    setisLoading(true);
    GetUsers()
      .then((response) => {
        setisLoading(false);
        const { data } = response;
        setusers(data?.data);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const loadMines = () => {
    httpClient.get("/api/v1/mines").then(response => {
      const { data } = response;
      setmines([...data])
    }).catch(err => {

    })
  }

  useEffect(() => {
    loadUsers();
    loadMines();
    return () => { };
  }, []);

  return (
    <>
      <Header />
      <PageHeader title={"Users"} />
      <Container>
        <SearchBar
          placeholder="Please enter an email address or a user name."
          search={(value) => {
            if (value.length > 3)
              httpClient
                .post("/api/v1/users/search", { search: value })
                .then((response) => {
                  const { data } = response;
                  setusers([...data?.data]);
                })
                .catch((err) => { });

            if (value.length === 0) loadUsers();
          }}
        />

        <UpdateCreateUserV2
          mainTitle="New User"
          reload={loadUsers}
          title="New User"
          mines={mines}
        />
        <ImportUser reload={loadUsers} title="Import  Users" />
        <Spinner isLoading={isLoading} />
        <DataTable
          pagination
          columns={[
            {
              name: "Email",
              selector: (row) => row.email,
              sortable: true,
              cell: (row) => <>{row.email}</>,
              grow: 2,
            },
            {
              name: "Name",
              selector: (row) => row.name,
              sortable: true,
            },
            {
              name: "Surname",
              selector: (row) => row.surname,
              sortable: true,
            },
            {
              name: "User Type",
              selector: (row) => row.role,
              sortable: true,
            },
            {
              name: "Creation Date",
              selector: (row) => row.creationDate,
              cell: (row) =>
                moment(row.creationDate).format("YYYY-MM-DD HH:mm"),
              sortable: true,
              grow: 2,
            },
            {
              name: "Status",
              selector: (row) => row.isEnable,
              cell: (row) =>
                row.isEnable ? (
                  <Badge bg="success">ACTIVE</Badge>
                ) : (
                  <Badge bg="danger">INACTIVE</Badge>
                ),
              sortable: true,
            },
            {
              cell: (row) => (
                <div>
                  <UpdateCreateUserV2
                    mainTitle="Edit User"
                    reload={loadUsers}
                    title={<EditFilled />}
                    _id={row._id}
                    color={"warning"}
                    mines={mines}
                  />
                  <ResetPassword {...row} />
                </div>
              ),
            },
          ]}
          data={users}
        />
      </Container>
    </>
  );
}
