import {
  DeleteFilled,
  DownCircleFilled,
  DownOutlined,
  InfoCircleFilled,
  WarningFilled,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import {
  AutoComplete,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Image,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Statistic,
  Tabs,
  Tag,
  Timeline,
  Spin,
  Cascader,
  DatePicker,
} from "antd";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { Download, EraserFill, PlusCircle } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayBasedOnRole } from "../components/DisplayBasedOnRole";
import { DownloadFile } from "../components/DownloadFile";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import SearchBar from "../components/SearchBar";
import Spinner from "../components/Spinner";
import { useUser } from "../hooks/user.hook";
import { httpClient } from "../services/http.service";
import { updateInputv2 } from "../utilities";

import "./default.css";

const repairsCode = {
  "ROXY 40": [
    {
      name: "Moisture Indicator ",
      image: "assets/roxy-40/moistureIndicator.jpg",
      codes: [
        {
          text: "Moisture indicator changed colour",
          code: "I1",
        },
        {
          text: "Moisture indicator cracked/damaged",
          code: "I2",
        },
        { text: "Moisture Indicator Missing", code: "I3" },
      ],
    },
    {
      name: "Belt Loops",
      image: "assets/roxy-40/Belt loops.JPG",
      codes: [
        { text: "Broken belt loop", code: "B1" },
        { text: "Belt loop razor edges", code: "B2" },
        { text: "Belt loop missing", code: "B3" },
        { text: "Lever flanges bent", code: "C9" },
      ],
    },
    {
      name: "Side Plate",
      image: "assets/roxy-40/sidePlate.png",
      codes: [
        { text: "Side plate missing", code: "P3" },
        { text: "Side plate damaged", code: "P4" },
        { text: "Side plate undue wear and tear", code: "P6" },
      ],
    },
    {
      name: "Side Plate",
      image: "assets/roxy-40/primarySeal.png",
      codes: [
        { text: "Primary seal missing", code: "S1" },
        {
          text: "Security seal (secondary seal) missing",
          code: "S2",
        },
      ],
    },
    {
      name: "Casing",
      image: "assets/roxy-40/cassing.png",
      codes: [
        {
          text: "Casing covered with gunnite, glue etc",
          code: "C1",
        },
        {
          text: "Casing damaged by electrical current",
          code: "C2",
        },
        { text: "Casing cracked", code: "C3" },
        { text: "Unit emptyd", code: "C4" },
        { text: "Unit damaged", code: "C5" },
        { text: "Unit crushed", code: "C6" },
        {
          text: "Unit burnt by mine (damaged by heat from brazing / welding / flame cutting)",
          code: "C7",
        },
        { text: "Casing has undue wear and tear", code: "C8" },
        { text: "Lid damaged", code: "C10" },
        {
          text: "External presence of diesel and oil",
          code: "O2",
        },
        { text: "Unit covered in paint", code: "O6" },
        { text: "External presence of paint", code: "O7" },
        { text: "Signs of corrosion", code: "U3" },
      ],
    },
  ],
  "AFROX PACK 35i": [
    {
      name: "Casing/Container",
      image: "assets/afrox/cassing.jpg",
      codes: [
        {
          text: "Unit Crushed",
          code: "Unit Crushed",
        },
        {
          text: "Unit battery arced",
          code: "Unit battery arced",
        },
        {
          text: "Hole under belt loop",
          code: "Hole under belt loop",
        },
        {
          text: "Top corners warn through",
          code: "Top corners warn through",
        },
        {
          text: "Bottom corners warn through",
          code: "Bottom corners warn through",
        },
        {
          text: "Unit cement covered",
          code: "Unit cement covered",
        },
        {
          text: "Unit has crack in corner",
          code: "Unit has crack in corner",
        },
        {
          text: "Unit is empty",
          code: "Unit is empty",
        },
        {
          text: "Unit has carry strap",
          code: "Unit has carry strap",
        },
        {
          text: "Unit has unnecessary dents",
          code: "Unit has unnecessary dents",
        },
        {
          text: "Unit vandalized",
          code: "Unit vandalized",
        },
        {
          text: "Cover wrong way",
          code: "Cover wrong way",
        },
      ],
    },
    {
      name: "Primary Seal",
      image: "assets/afrox/primary_seal.jpg",
      codes: [
        {
          text: "Primary seal missing",
          code: "Primary seal missing",
        },
      ],
    },
    {
      name: "Secondary Seal",
      image: "assets/afrox/secondary_seal.jpg",
      codes: [
        {
          text: "Secondary seal missing",
          code: "Secondary seal missing",
        },
      ],
    },
    {
      name: "Release Lever",
      image: "assets/afrox/release_lever.jpg",
      codes: [
        {
          text: "Cement under release lever",
          code: "Cement under release lever",
        },
        {
          text: "Release Lever damadged",
          code: "Release Lever damadged",
        },
      ],
    },
    {
      name: "Belt Loops",
      image: "assets/afrox/belt_loops.jpg",
      codes: [
        {
          text: "Belt loop broken",
          code: "Belt loop broken",
        },
        {
          text: "Belt loop Sharp edges",
          code: "Belt loop Sharp edges",
        },
        {
          text: "Belt loop bend",
          code: "Belt loop bend",
        },
      ],
    },
    {
      name: "Moisture Indicator",
      image: "assets/afrox/moisture_indicator.jpg",
      codes: [
        {
          text: "Moisture indicator is white/pink",
          code: "Moisture indicator is white/pink",
        },
        {
          text: "Moisture indicator Broken",
          code: "Moisture indicator Broken",
        },
      ],
    },
    {
      name: "Serial Number",
      image: "assets/afrox/serial_number.jpg",
      codes: [
        {
          text: "Serial number not visible",
          code: "Serial number not visible",
        },
      ],
    },
    {
      name: "Closing Strap",
      image: "assets/afrox/closing_strap.jpg",
      codes: [
        {
          text: "Closing Stap Dammedged",
          code: "Closing Stap Dammedged",
        },
      ],
    },
  ],
};

const { RangePicker } = DatePicker;


export function RepairRFQDetails(props) {
  const { id } = useParams();
  const [RFQDetails, setRFQDetails] = useState({});
  const [isLoading, setisLoading] = useState(false);

  const { user } = useUser();
  const navigate = useNavigate();

  const [scsrquarantined, setscsrquarantined] = useState();

  const [options, setoptions] = useState({
    disabled: false,
    createMode: false,
  });

  const [RFQCDs, setRFQCDs] = useState();

  const [mines, setmines] = useState([]);

  const [repairs, setrepairs] = useState([]);

  const [totalRepairCost, settotalRepairCost] = useState(null);

  const [invoiceNumber, setinvoiceNumber] = useState(0);

  const [repairListitems, setrepairListitems] = useState([]);

  const [availableSCSRsForRepair, setavailableSCSRsForRepair] = useState([]);

  const [selectedMine, setselectedMine] = useState();

  const loadRepairInformationForOneSCSR = (scsrId, update = false) => {
    httpClient
      .get(
        `/api/v1/scsr/${scsrId}/inspectionrecords/fulldetails?mode=latest&rfqId=${id}`
      )
      .then((res) => {
        setselectedSCSRToRepair({ ...res?.data, isUpdate: update });
      })
      .catch((err) => {});
  };

  const deleteRepairRecord = (inspextionId) => {
    httpClient
      .delete(
        `/api/v1/scsr/${inspextionId}/repair`
      )
      .then((res) => {

        loadRepairList();
        loadRepairsRecords();
        
      })
      .catch((err) => {

      });
  };


  const loadRepairInformation = () => {
    if (id === "new") return;

    setisLoading(true);
    httpClient
      .get(`/api/v1/RFQ/${id}`)
      .then((response) => {
        const { data } = response;
        setRFQDetails({
          ...data,
          scsrsId: data?.scsrs?.map((scsr) => scsr._id),
        });

        //loadQuanrantinedSCSRS("quarantined", data?.mine?._id);

        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const loadMines = () => {
    httpClient
      .get(`/api/v1/mines`)
      .then((response) => {
        const { data } = response;
        setmines([...data]);
      })
      .catch((err) => {});
  };

  const loadQuanrantinedSCSRS = (status, mine) => {
    setisLoading(true);
    httpClient
      .get(`/api/v1/scsr/status/non active/mine/${mine}`)
      .then((res) => {
        const { data } = res;
        setscsrquarantined([...data]);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const loadRepairsRecords = () => {

    setscsrToBeRepairOptions([]);
    setisLoading(true);
    httpClient
      .get(`/api/v1/RFQ/${id}/repair`)
      .then((res) => {
        const { data } = res;
        setrepairs([...data]);
        setisLoading(false);

        calculateTotalCostOfRepair([...data]);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const loadCD = () => {
    setisLoading(true);
    httpClient
      .get(`/api/v1/RFQ/${id}/CD`)
      .then((res) => {
        const { data } = res;
        setRFQCDs([...data]);
        setisLoading(false);
      })
      .catch((e) => {
        setisLoading(false);
      });
  };

  const submitInformation = (e) => {
    if (id === "new") {
      httpClient
        .post(`/api/v1/RFQ`, {
          mine: RFQDetails?.mine?._id,
          scsrs: RFQDetails?.scsrs?.map((scsr) => scsr._id),
          rfqs: RFQDetails?.rfqs,
        })
        .then((res) => {
          message.success(`RFQ created successfully !`);
          navigate("/app/repairs/");
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message);
            return;
          }

          if (error?.response?.data) {
            message.warning(error?.response?.data);
            return;
          }
        });

      return;
    } else {
      httpClient
        .put(`/api/v1/RFQ/${id}`, {
          rfqs: RFQDetails?.rfqs,
          scsrs:RFQDetails?.scsrs?.map((scsr) => scsr._id),
        })
        .then((res) => {
          const { data } = res;
          message.success(`Information saved successfully !`);
          loadRepairInformation();
        })
        .catch((error) => {
          message.warning(error?.response?.data || error?.message);
        });
    }
  };

  const calculateTotalCostOfRepair = (repairRaw) => {
    let totalRepairsOnItems = repairRaw
      ?.map((scsr) => {
        let total = scsr?.itemsRepaired?.reduce(
          (prev, current) => prev + current?.cost || 0,
          0
        );
        return total;
      })
      .reduce((prev, current) => prev + current, 0);

    settotalRepairCost(totalRepairsOnItems);
  };

  const loadRepairList = () => {
    httpClient
      .get("/api/v1/repair/repairlist")
      .then((response) => {
        setrepairListitems([...(response?.data || [])]);
      })
      .catch((err) => {});
  };

  const getAvailableSCSRAvailableForRepairs = (serialNumber) => {
    httpClient
      .get(`/api/v1/RFQ/${id}/scsrs/search?status=repairsystem`)
      .then((response) => {
        setavailableSCSRsForRepair([
          ...response?.data?.map((a) => ({
            label: a?.serialNumber,
            value: a?._id,
            status:a?.status
          })),
        ]);
      })
      .catch((err) => {});
  };

  const [selectedSCSRToRepair, setselectedSCSRToRepair] = useState(null);

  const [scsrsOptionsFound, setscsrsOptionsFound] = useState([]);

  const [scsrToBeRepairOptions, setscsrToBeRepairOptions] = useState([]);

  const SeachForCSR = (serialNumber) => {
    setisLoading(true);

    if (serialNumber.length < 3) {
      setisLoading(false);
      return;
    }

    httpClient
      .get(
        `/api/v1/scsr/search?serialNumber=${serialNumber}&mine=${RFQDetails?.mine?._id}`
      )
      .then((response) => {
        const { data } = response;
        setisLoading(false);
        if (data?.length === 0) {
          //alert(`${serialNumber} not found !, please check that you have selected the right mine !`);
          return;
        }

        if (data[0]?.underRepair) {
          alert(
            `${data[0]?.serialNumber} is already been assigned to rfq ${
              data[0]?.underRepair?.rfqs && data[0]?.underRepair?.rfqs[0]
            }`
          );
          return;
        }

        if ((data[0]?.status !== "quarantined") && (data[0]?.status !== "expired")) {
          alert(`${data[0]?.serialNumber} is not in a quarantined or expired state !`);
          return;
      }
      

        

        if (RFQDetails?.scsrs?.find((scsr) => scsr._id === data[0]?._id)) {
          alert(`${data[0]?.serialNumber} has aleady been added`);
          return;
        }

        let currentscsrList = RFQDetails?.scsrs;
        currentscsrList?.push(data[0]);

        setRFQDetails((old) => ({ ...old, scsrs: currentscsrList }));
      })
      .catch((er) => {
        setisLoading(false);
      });
  };

  const ChangeStatusOfRFQ = (status) => {
    setisLoading(true);
    httpClient
      .post(`/api/v1/RFQ/${id}/status?status=${status}`)
      .then((response) => {
        loadRepairInformation();
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    loadMines();
    loadRepairInformation();
    loadRepairList();

    if (id === "new") {
      setoptions((old) => ({ ...old, createMode: true, disabled: false }));
    } else {
      if (!user?.roles?.find((role) => role === "rfq.create")) {
       // setoptions((old) => ({ ...old, disabled: true }));
      }
    }
  }, []);

  return (
    <>
      <Header />
      <PageHeader title={"RFQ & Repairs Information"} />
      <p style={{ margin: "5px", textAlign: "center" }}>
        <h4>
          {" "}
          {RFQDetails?.rfqs && RFQDetails?.rfqs[0]} - {RFQDetails?.mine?.name}{" "}
        </h4>
      </p>

      <Spin size="large" spinning={isLoading}>
        <Container>
          {/* <h3>{RFQDetails?.status}</h3>
        <Select placeholder={"Status"} defaultValue={RFQDetails?.status}>
    
        </Select> */}
          <Row gutter={16}>
            <Col span={4}>
              <Statistic
                title="Total SCSRs"
                value={RFQDetails?.scsrs?.length}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Total SCSRs awaiting repair"
                value={
                  RFQDetails?.scsrs?.filter(
                    (scsr) => scsr.status === "collected"
                  ).length
                }
              />
            </Col>

            <Col span={4}>
              <Statistic
                title="Total SCSRs repaired"
                value={repairs?.length}
                precision={0}
              />
            </Col>
            {totalRepairCost && (
              <Col span={4}>
                <Statistic
                  title="Total Cost"
                  prefix="R"
                  value={totalRepairCost}
                  precision={0}
                />
              </Col>
            )}
          </Row>


          <Tabs
            onChange={(e) => {
              if (e === "2") {
                loadCD();
                loadRepairInformation();
                settotalRepairCost(null);
              }
              if (e === "3") {
                loadRepairsRecords();
                loadRepairInformation();
                getAvailableSCSRAvailableForRepairs();
              }
            }}
            defaultActiveKey="1"
          >
            <Tabs.TabPane tab="RFQ Details" key="1">
              <ChangeRFQStatus
                handleChange={ChangeStatusOfRFQ}
                status={RFQDetails?.status}
                reload={() => {
                  loadRepairInformation();
                }}
              />
              <Form
                disabled={options?.disabled}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 15 }}
                layout="horizontal"
              >
                <Collapse defaultActiveKey={["1", "2"]}>
                  <Collapse.Panel header="Mine Informaion" key="1">
                    <Form.Item label="Mine">
                      <Select
                        disabled={RFQDetails?._id}
                        value={RFQDetails?.mine?._id}
                        onChange={(e) => {
                          updateInputv2({ _id: e }, "mine", setRFQDetails);
                          setRFQDetails((old) => ({
                            ...old,
                            scsrs: [],
                            scsrsId: [],
                          }));
                          //loadQuanrantinedSCSRS("quarantined", e);
                        }}
                      >
                        {mines?.map((rfq) => (
                          <Select.Option key={rfq._id} value={rfq._id}>
                            {rfq.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="RFQS / PO">
                      {}
                      <Input
                        readOnly={RFQDetails?._id}
                        value={RFQDetails?.rfqs && RFQDetails?.rfqs[0]}
                        onChange={(e) =>
                          updateInputv2([e.target.value], "rfqs", setRFQDetails)
                        }
                        placeholder="RFQS / PO"
                      ></Input>
                      {/* <Select
                      mode="tags"
                      value={RFQDetails?.rfqs}
                      onChange={(e) => updateInputv2(e, "rfqs", setRFQDetails)}
                    >
                      {RFQDetails?.rfqs?.map((rfq) => (
                        <Select.Option key={rfq} value={rfq}>
                          {rfq}
                        </Select.Option>
                      ))}
                    </Select> */}
                    </Form.Item>
                  </Collapse.Panel>
                  <Collapse.Panel header="SCSRs" key="2">
                    <Form.Item label="*">
                      <AutoComplete
                        options={scsrsOptionsFound}
                        placeholder=""
                        onSearch={(e) => {
                          SeachForCSR(e);
                        }}
                      >
                        <Input.Search
                          size="large"
                          placeholder="Search for SCSRS"
                        />
                      </AutoComplete>
                    </Form.Item>

                    {/* <RFQSCSRList scsrs={RFQDetails?.scsrs} /> */}
                    <Form.Item label="SCSRS">


                    <DataTable
                data={RFQDetails?.scsrs}
                columns={[
                  {
                    name:"Serial Number",
                    cell:row=> row?.serialNumber,
                    sortable:true,
                     
                  },
                    {
                    name:"Status",
                    cell:row=> <div
                    style={{
                      color:
                      row?.status === "quarantined"
                          ? "green"
                          : "red",
                    }}
                  >
                    {row?.status?.toUpperCase()}
                  </div>,
                    sortable:true,
                    
                  },
                   {
                    name:"",
                    cell:row=> <Popconfirm
                    title={`Are You sure You remove ${row?.serialNumber} from this RFQ, this will cause ${row?.serialNumber} to be reactivated ?`}
                    description="Update RFQ"
                    onConfirm={() => {
                      let newList = RFQDetails?.scsrs?.filter(scsr=> scsr?.serialNumber !==row?.serialNumber);
                      setRFQDetails(old=>({...old, scsrs:[...newList]}));

                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    placement="topLeft"
                    cancelText="No"
                  >
                    <Button
                    size="sm"
                      style={{ margin: "2px" }}
                     
                      type="primary"

                    >
                      <DeleteFilled/>
                    </Button>
                  </Popconfirm>,
                   
                     
                  },
                  ]} />
                     {/* <DataTable
                data={RFQDetails?.scsrs}
                columns={[
                  {
                    name:"Serial Number",
                    cell:row=> row?.serialNumber,
                    sortable:true,
                     
                  },
                  
                  // {
                  //   name:"Status",
                  //   cell:row=> <div
                  //   style={{
                  //     color:
                  //     row?.status === "quarantined"
                  //         ? "green"
                  //         : "red",
                  //   }}
                  // >
                  //   {row?.status?.toUpperCase()}
                  // </div>,
                  //   sortable:true,
                    
                  // },
                  // {
                  //   name:"",
                  //   cell:row=> <Popconfirm
                  //   title={`Are You sure You remove ${row?.serialNumber} from this RFQ, this will cause ${row?.serialNumber} to be reactivated ?`}
                  //   description="Update RFQ"
                  //   onConfirm={() => {
                  //     let newList = RFQDetails?.scsrs?.filter(scsr=> scsr?.serialNumber !==row?.serialNumber);
                  //     setRFQDetails(old=>({...old, scsrs:[...newList]}));

                  //   }}
                  //   onCancel={() => {}}
                  //   okText="Yes"
                  //   placement="topLeft"
                  //   cancelText="No"
                  // >
                  //   <Button
                  //   size="sm"
                  //     style={{ margin: "2px" }}
                     
                  //     type="primary"

                  //   >
                  //     <DeleteFilled/>
                  //   </Button>
                  // </Popconfirm>,
                   
                     
                  // },
                ]}
                pagination
                ></DataTable> */}
                
                

                    </Form.Item>

                    
                  </Collapse.Panel>
                </Collapse>
                <Form.Item wrapperCol={{ offset: 5, span: 15 }}>
                  <Popconfirm
                    title="Are You sure You want to make changes ?"
                    description="Update RFQ"
                    onConfirm={() => submitInformation()}
                    onCancel={() => {}}
                    okText="Yes"
                    placement="topLeft"
                    cancelText="No"
                  >
                    <Button
                      style={{ marginTop: "20px" }}
                      disabled={options?.disabled}
                      type="primary"
                      onClick={(e)=> {submitInformation()}}
                    >
                      Save
                    </Button>
                  </Popconfirm>
                </Form.Item>

                {/* <div style={{textAlign:"end"}}>
          <Button >Save</Button>
          </div> */}
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Delivery - Collection" key="2">
              <DownloadFile
                disabled={
                  RFQDetails?.scsrs?.filter(
                    (scsrf) => (scsrf?.status === "quarantined" || scsrf?.status === "expired")
                  ).length === 0
                }
                fileUrl={`/api/v1/RFQ/${id}/collection/download?mode=quarantined&fileSuffix=RFQ_MCF&documentTile=RFQ MINE COLLECTION FORM&other=MINE`}
                buttonTitle="Downlaod RFQ MINE Collection Form"
                onError={(e) => alert(e?.message)}
              />
              <DownloadFile
                disabled={
                  RFQDetails?.scsrs?.filter(
                    (scsrf) => (scsrf?.status === "collected" || scsrf?.status === "expired")
                  ).length === 0
                }
                fileUrl={`/api/v1/RFQ/${id}/collection/download?mode=collected&fileSuffix=RFQ_ODF&documentTile=RFQ OEM DELIVERY FORM&other=OEM`}
                buttonTitle="Downlaod RFQ OEM Delivery Form"
                onError={(e) => alert(e?.message)}
              />
              <DownloadFile
                disabled={
                  RFQDetails?.scsrs?.filter(
                    (scsrf) => scsrf?.status === "repaired"
                  ).length === 0
                }
                fileUrl={`/api/v1/RFQ/${id}/collection/download?mode=repaired&fileSuffix=RFQ_ODF&documentTile=RFQ OEM COLLECTION FORM&other=OEM`}
                buttonTitle="Downlaod RFQ OEM Collection Form"
                onError={(e) => alert(e?.message)}
              />
              <DownloadFile
                disabled={
                  RFQDetails?.scsrs?.filter(
                    (scsrf) => scsrf?.status === "enroute"
                  ).length === 0
                }
                fileUrl={`/api/v1/RFQ/${id}/collection/download?mode=enroute&fileSuffix=RFQ_ODF&documentTile=RFQ CUSTOMER DELIVERY FORM&other=OEM`}
                buttonTitle="Downlaod RFQ Customer Delivery Form"
                onError={(e) => alert(e?.message)}
              />
              <DataTable
                data={RFQCDs}
                pagination
                expandableRows
                expandableRowsComponent={(e) => (
                  <>
                    <Divider orientation="left">SCSRs</Divider>
                    <div style={{ textAlign: "left", margin: "20px" }}>
                      {e.data.scsrs?.map((scsr) => (
                        <Tag
                          onClick={(e) => navigate(`/app/scsrs/${scsr?._id}`)}
                          color="magenta"
                        >
                          {scsr?.serialNumber}
                        </Tag>
                      ))}
                    </div>
                  </>
                )}
                columns={[
                  {
                    sortable: true,
                    name: "Creation Date",
                    cell: (row) =>
                      moment(row?.creationDate).format("YYYY-MM-DD HH:mm"),
                  },
                  {
                    sortable: true,
                    selector: (row) => row?.user?._id,
                    name: "User",
                    cell: (row) => `${row?.user?.name} ${row?.user?.surname}`,
                  },
                  {
                    selector: (row) => row?.waybillNumber,
                    sortable: true,
                    name: "Wayill Number",
                    cell: (row) => row?.waybillNumber,
                  },
                  {
                    selector: (row) => row?.type,
                    sortable: true,
                    name: "Type",
                    cell: (row) => row?.type,
                  },

                  {
                    name: "SRSCs",
                    cell: (row) => <>{row?.scsrs?.length}</>,
                  },

                  {
                    name: "Signature",
                    cell: (row) => (
                      <>
                        <Image
                          width={"50px"}
                          height={"50px"}
                          src={row?.clientInformation}
                        />
                      </>
                    ),
                  },
                  {
                    name: "Picture",
                    cell: (row) => (
                      <>
                        <Image
                          width={"50px"}
                          height={"50px"}
                          src={row?.more?.AttachPicture}
                        />
                      </>
                    ),
                  },

                  {
                    name: "",
                    cell: (row) => (
                      <>
                        {/* <Button
                  size="sm"
                  onClick={(e) => {
                    navigate(`/app/repairs/${row._id}`);
                  }}
                >
                  <InfoCircleFilled />
                </Button> */}
                      </>
                    ),
                  },
                ]}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="SCSR Repairs" key="3">
              {repairs?.length >= RFQDetails?.scsrs?.length && (
                <DownloadInvoice
                  id={id}
                  isLoading={isLoading}
                  setisLoading={(e) => setisLoading(e)}
                />
              )}

              <Input.Search
                onChange={(e) => {
                  if (e.currentTarget.value.length > 3)
                    setscsrToBeRepairOptions([
                      ...availableSCSRsForRepair?.filter((a) =>
                        a.label.includes(e.currentTarget.value)
                      ),
                    ]);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter")
                    setscsrToBeRepairOptions([
                      ...availableSCSRsForRepair?.filter((a) =>
                        a.label.includes(e.currentTarget.value)
                      ),
                    ]);
                }}
                size="large"
                placeholder="Search for SCSRS"
              />

              {/* <Select
                style={{ width: "200px" }}
                showSearch
                placeholder="Select a SCSR"
                optionFilterProp="children"
                onClick={(e) => getAvailableSCSRAvailableForRepairs(e)}
                onChange={(e) => {
                  loadRepairInformationForOneSCSR(e);
                }}
                onSearch={(e) => {
                  getAvailableSCSRAvailableForRepairs(e);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={availableSCSRsForRepair}
              /> */}

              <></>
              {scsrToBeRepairOptions?.map((scsrToRepair) => (
                <Tag
                  onClick={() =>
                    loadRepairInformationForOneSCSR(scsrToRepair.value)
                  }
                  style={{ margin: 2 }}
                  size="sm"
                >
                  {scsrToRepair?.label} 
                </Tag>
              ))}

              <FullRepairModal
                rfqId={id}
                status={RFQDetails?.status}
                isModalOpen={selectedSCSRToRepair}
                setIsModalOpen={(e) => setselectedSCSRToRepair(null)}
                repairList={repairListitems}
                reload={() => {
                  loadRepairsRecords();
                  
                  getAvailableSCSRAvailableForRepairs();
                }}
                completeRFQ={() => ChangeStatusOfRFQ("COMPLETED")}
              />

              <DataTable
                data={repairs}
                pagination
                expandableRows
                expandableRowsComponent={(e) => (
                  <>
                    {/* <Divider orientation="left">SCSRs</Divider>
                                <div style={{ textAlign: "left", margin: "20px" }}>
                                    {e.data.scsrs?.map(scsr => <Tag color="magenta">{scsr?.serialNumber}</Tag>)}
                                </div> */}

                    <Descriptions bordered column={2}>
                      <Descriptions.Item label="Repaired By">
                        {e.data?.repairedBy?.name} {e.data?.repairedBy?.surname}
                      </Descriptions.Item>
                      <Descriptions.Item label="Items Repaired">
                        {" "}
                        <p>
                          {" "}
                          {e.data?.itemsRepaired?.map((itemRepaired) => (
                            <>
                              {itemRepaired?.description} -{" "}
                              {itemRepaired?.details} : {itemRepaired?.cost}{" "}
                              <br />
                            </>
                          ))}
                        </p>
                      </Descriptions.Item>
                      <Descriptions.Item label="Total Repair Cost">
                        {JSON.stringify(
                          e.data?.itemsRepaired?.reduce(function (acc, obj) {
                            return acc + obj?.cost;
                          }, 0)
                        )}
                      </Descriptions.Item>
                      {/* <Descriptions.Item  label="Item Repaired">
                      <Select  defaultValue={e.data?.itemsRepaired?.map(a=>JSON.stringify(a))} mode="multiple" style={{width:"300px"}} placeholder="Please select repaired Items">
                        {repairListitems?.map((repairList,index)=> <Select.Option value={JSON.stringify(repairList)} title={repairList?.details}>{repairList?.description}</Select.Option>)}
                      </Select>
                    </Descriptions.Item> */}
                    </Descriptions>
                  </>
                )}
                columns={[
                  {
                    sortable: true,
                    name: "Creation Date",
                    cell: (row) =>
                      moment(row?.creationDate).format("YYYY-MM-DD HH:mm"),
                  },
                  {
                    sortable: true,
                    name: "User",
                    selector: (row) => row?.repairedBy?._id,
                    cell: (row) =>
                      `${row?.repairedBy?.name} ${row?.repairedBy?.surname}`,
                  },
                  {
                    sortable: true,
                    name: "SCSR",
                    selector: (row) => row?.scsr,
                    cell: (row) => <>{row?.scsr?.serialNumber}</>,
                  },
                  {
                    sortable: true,
                    name: "Status",
                    selector: (row) => row?.status,
                    cell: (row) => row?.status?.toUpperCase(),
                  },

                  {
                    name: "",
                    cell: (row) => (
                      <>
                        {RFQDetails?.status === "REPARING" && (
                          <Button
                         variant="warning"
                          style={{margin:"2px"}}
                            size="sm"
                            onClick={() =>
                              loadRepairInformationForOneSCSR(
                                row?.scsr?._id,
                                true
                              )
                            }
                          >
                            <InfoCircleFilled />
                          </Button>
                        )}

{RFQDetails?.status === "REPARING" && (
                          <Button
                          style={{margin:"2px"}}
                            size="sm"
                            onClick={() =>
                              deleteRepairRecord(
                                row?._id
          
                              )
                            }
                          >
                            <EraserFill />
                          </Button>
                        )}

                        {/* <Button
                  size="sm"
                  onClick={(e) => {
                    navigate(`/app/repairs/${row._id}`);
                  }}
                >
                  <InfoCircleFilled />
                </Button> */}
                      </>
                    ),
                  },
                ]}
              />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="SCSR Repairs Activities" key="4"></Tabs.TabPane> */}
          </Tabs>
        </Container>
      </Spin>
    </>
  );
}

export default function Repairs() {
  const navigate = useNavigate();

  const [rfqs, setrfqs] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  function searchForRFQS(rfq) {
    setisLoading(true);
    httpClient
      .get(`/api/v1/RFQ?rfq=${rfq}`)
      .then((response) => {
        const { data } = response;
        setrfqs([...data]);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  }

  function getRFQS() {
    setisLoading(true);
    httpClient
      .get("/api/v1/RFQ")
      .then((response) => {
        const { data } = response;
        setrfqs([...data]);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        // message.error(error?.response?.data?.message || error)
      });
  }

  useEffect(() => {
    getRFQS();
  }, []);

  return (
    <>
      <Header />
      <PageHeader title={"Repairs RFQs"} />

      <Container>
        <SearchBar
          placeholder="Please enter RFQ Number ..."
          search={(e) => searchForRFQS(e)}
        />
        {/* <DisplayBasedOnRole roles={["Admin", "Repairman","Inspector"]}>
        <DownloadRFQReports />
        </DisplayBasedOnRole> */}
        
        {/* <Radio.Group >
                    <Radio value={1}>RFQ</Radio>
                    <Radio value={2}>Waybill Number</Radio>                   
                </Radio.Group> */}
        {/* <Spinner isLoading={isLoading} /> */}
        <Spin size="large" indicator={true} spinning={isLoading}>
          <DisplayBasedOnRole roles={["Admin"]}>
            <Button
              size="sm"
              onClick={(e) => {
                navigate(`/app/repairs/new`);
              }}
            >
              <PlusCircle /> New RFQ Order
            </Button>
          </DisplayBasedOnRole>

          <DataTable
            data={rfqs}
            conditionalRowStyles={[
              {
                when: (row) => row?.status === "COMPLETED",
                style: { color: "green" },
              },
              {
                when: (row) => row?.status === "INITIATED",
                style: { color: "red" },
              },
              {
                when: (row) => row?.status === "REPARING",
                style: { color: "orange" },
              },
            ]}
            pagination
            columns={[
              {
                name: "Creation Date",
                sortable: true,
                selector: (row) => row?.creationDate,
                cell: (row) =>
                  moment(row?.creationDate).format("YYYY-MM-DD HH:mm"),
              },
              {
                sortable: true,
                selector: (row) => row?.createdBy?._id,
                name: "Created By",
                cell: (row) => (
                  <div style={{ textAlign: "center" }}>
                    {!row?.createdBy
                      ? "-"
                      : `${row?.createdBy?.name} ${row?.createdBy?.surname}`}
                  </div>
                ),
              },
              {
                sortable: true,
                selector: (row) => row?.mine?.name,
                name: "Mine Name",
                cell: (row) => row?.mine?.name,
              },
              {
                name: "RFQS",
                cell: (row) => (
                  <ul>
                    {row?.rfqs?.map((rfq) => (
                      <li>{rfq}</li>
                    ))}
                  </ul>
                ),
              },

              {
                sortable: true,
                selector: (row) => row?.scsrs?.length,
                name: "SRSCs",
                cell: (row) => <>{row?.scsrs?.length}</>,
              },
              // {
              //     sortable:true,
              //     selector:row=>row?.scsrs?.filter(scsr=> scsr.status==="collected").length,
              //     name: "SRSCs Collected",
              //     cell: (row) => <div style={{color:row?.scsrs?.filter(scsr=> scsr.status==="collected").length === row?.scsrs?.length ? "green":"red"}}>{row?.scsrs?.filter(scsr=> scsr.status==="collected").length}</div>,
              // },
              {
                sortable: true,
                selector: (row) =>
                  row?.scsrs?.filter((scsr) => scsr.status === "collected")
                    .length,
                name: "SRSCs Repaired",
                cell: (row) => (
                  <>
                    {
                      row?.scsrs?.filter((scsr) => scsr.status === "repaired")
                        .length
                    }
                  </>
                ),
              },
              {
                name: "Complition Date",
                sortable: true,
                selector: (row) => row?.completionDate,
                cell: (row) => row?.completionDate ? 
                  moment(row?.completionDate).format("YYYY-MM-DD HH:mm") : ""  ,
              },
              
              {
                sortable: true,
                selector: (row) => row?.status,
                name: "Status",
                cell: (row) => row?.status,
              },
              {
                name: "Action",
                cell: (row) => (
                  <>
                    <Button
                      size="sm"
                      onClick={(e) => {
                        navigate(`/app/repairs/${row._id}`);
                      }}
                    >
                      <InfoCircleFilled />
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </Spin>
      </Container>
    </>
  );
}

const DownloadInvoice = (props) => {
  const [invoiceNumber, setinvoiceNumber] = useState("");

  function showFile(fileReceived) {
    let filename = fileReceived[0].filename;
    // other operations
  }
  const donwload = () => {
    props.setisLoading(true);
    fetch(`/api/v1/RFQ/${props.id}/invoice?invoiceNumber=${invoiceNumber}`, {
      method: "POST",
      headers: {
        "x-auth": "jwtValueRow",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((response) => {
      const filename = response.headers
        .get("Content-Disposition")
        .split("filename=")[1];
      response
        .blob()
        .then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob); // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = filename.replace('"', "").replace('"', "");

          alink.click();

          props.setisLoading(false);
        })
        .catch((err) => {
          props.setisLoading(false);
        });
    });
  };

  return (
    <>
      <Input
        maxLength={10}
        style={{ width: "350px", margin: "10px" }}
        value={invoiceNumber}
        addonBefore="Invoice #"
        placeholder="Please enter an invoice number"
        onChange={(e) => setinvoiceNumber(e.target?.value?.toLocaleUpperCase())}
      />

      <Button
        size="sm"
        onClick={donwload}
        style={{ margin: "10px" }}
        disabled={invoiceNumber?.length < 2 || props?.isLoading}
      >
        <Download /> Download Invoice
      </Button>
    </>
  );
};

const FullRepairModal = ({
  isModalOpen,
  setIsModalOpen,
  repairList,
  rfqId,
  reload,
  status,
  completeRFQ,
}) => {
  const handleOk = () => {};

  const [issaving, setissaving] = useState(false);

  const displayRender = (labels) => labels[labels.length - 1];

  const [repairInformationDetails, setrepairInformationDetails] = useState({
    status: "repaired",
    comments: "",
  });

  const handleCancel = () => {
    setIsModalOpen(false);
    setrepairInformationDetails({ status: "repaired" });
  };

  // const loadRFQDetails = () => {
  //   httpClient.get();
  // };

  const CompleteRFQ = () => {
    Modal.info({
      okText: "Yes",
      okCancel: "Cancel",
      title: "Repair Completion Confirmation",
      content: (
        <div>
          <p>
            All repairs requested have been completed. Do you want to mark this
            RFQ as complete?
          </p>
        </div>
      ),
      onOk() {
        completeRFQ();
      },
      onCancel() {},
    });
  };

  const handleSave = (id) => {
    setissaving(true);
    if (isModalOpen?.isUpdate) {
      httpClient
        .put(
          `/api/v1/repair/${isModalOpen?.latestRepair?._id}?rfqId=${rfqId}`,
          {
            ...repairInformationDetails,
            scsr: isModalOpen?.scsr?._id,
            inspection: isModalOpen?.scsr?.latestInspection,
            issuesReported: {},
          }
        )
        .then((response) => {
          const { data } = response;
          //setshowRepairModal(false);
          //setrepairInformation({});

          if (reload) reload();

          alert(`The repair record has been updated successfully !`);
          handleCancel();
          setissaving(false);

          if (data?.isRFQCompleted) CompleteRFQ();

          //  history.push(`/app/scsr`,"forward","replace");
        })
        .catch((err) => {
          alert(err?.response?.data || err?.message);
          setissaving(false);
        });

      return;
    }

    httpClient
      .post(`/api/v1/RFQ/${rfqId}/repair?rfqId=${rfqId}`, {
        ...repairInformationDetails,
        scsr: isModalOpen?.scsr?._id,
        inspection: isModalOpen?.scsr?.latestInspection,
        issuesReported: {},
      })
      .then((response) => {
        const { data } = response;
        //setshowRepairModal(false);
        setrepairInformationDetails({ actionTaken: "repaired" });
       
        // history.push(`/app/scsr`,"forward","replace");
        alert(`The repair record has been saved successfully !`);

        if (reload) reload();

        if (data?.isRFQCompleted) CompleteRFQ();

        handleCancel();
        setissaving(false);
      })
      .catch((err) => {
        alert(err?.response?.data || err?.message);
        setissaving(false);
      });
  };

  useEffect(() => {
    setrepairInformationDetails({
      status: isModalOpen?.latestRepair?.status || "repaired",
      comments: isModalOpen?.latestRepair?.comments,
      itemsRepaired: isModalOpen?.latestRepair?.itemsRepaired,
      issueDiscovered: isModalOpen?.latestRepair?.issueDiscovered,
    });

    return () => {};
  }, [isModalOpen]);

  return (
    <>
      <Modal
        footer={[
          (status === "REPARING" ||
            isModalOpen?.scsr?.status === "rrepair" || isModalOpen?.scsr?.status==="expired") && (
            <Button size="sm" disabled={issaving} onClick={handleSave}>
              Save
            </Button>
          ),
        ]}
        title={`Repairing `}
        width={"800px"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Descriptions
          style={{ marginBottom: "10px" }}
          title="SCSR Information"
          bordered
          column={2}
        >
          <Descriptions.Item label="Serial Number">
            {isModalOpen?.scsr?.serialNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Mine Number">
            {isModalOpen?.scsr?.mineNumber}
          </Descriptions.Item>
          <Descriptions.Item
            label="Status"
            style={{
              color:
                isModalOpen?.scsr?.status?.toUpperCase() === "ACTIVE"
                  ? "green"
                  : "red",
            }}
          >
            {isModalOpen?.scsr?.status?.toUpperCase()}
          </Descriptions.Item>
          <Descriptions.Item label="Warranty Expiration">
            {moment(isModalOpen?.scsr?.warrantyExpiration).format("YYYY-MM")}
          </Descriptions.Item>
          <Descriptions.Item label="Latest Comment">
            {isModalOpen?.scsr?.comment}
          </Descriptions.Item>
        </Descriptions>

        <Collapse defaultActiveKey={["1"]}>
          <Collapse.Panel header="Inspections Timeline" key="1">
            <Timeline mode="alternate">
              {isModalOpen?.inspections?.map((timeLine) => (
                <Timeline.Item
                  color="red"
                  label={`${moment(timeLine?.inspectionDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )} ${timeLine?.data?.status}`}
                >
                  <p>
                    Inspected By :{timeLine?.data?.inspection?.user?.name}{" "}
                    {timeLine?.data?.inspection?.user?.surname}
                  </p>

                  <p>
                    {timeLine?.data?.code?.map((code) => (
                      <>
                        ({code?.code}) {code?.text}
                      </>
                    ))}
                  </p>
                  <p>Comments:</p>
                </Timeline.Item>
              ))}
            </Timeline>
          </Collapse.Panel>
          <Collapse.Panel header="Repair Information Timeline" key="2">
            <Radio.Group
              defaultValue={repairInformationDetails?.status}
              value={repairInformationDetails?.status}
              onChange={(e) => {
                updateInputv2(
                  e.target.value,
                  "status",
                  setrepairInformationDetails,
                  false
                );
              }}
            >
              <Radio value={"repaired"}>REPAIRED</Radio>
              <Radio value={"ber"}>BER</Radio>
              {/* <Radio value={"canceled"}>CANCELED</Radio> */}
            </Radio.Group>

            <div>
              <Input.TextArea
                value={repairInformationDetails?.comments}
                onChange={(e) => {
                  updateInputv2(
                    e.target.value,
                    "comments",
                    setrepairInformationDetails,
                    false
                  );
                }}
                style={{ width: "400px", marginBottom: "10px" }}
                placeholder="Comments"
              ></Input.TextArea>
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <p> Issue Discovered {}</p>

              <Cascader
                style={{ width: "400px" }}
                multiple
                placeholder="Issue Discovered"
                options={repairsCode[isModalOpen?.scsr?.model]?.map(
                  (repairCode) => {
                    let children = repairCode?.codes?.map((code) => ({
                      value: `${code.code} - ${code.text}`,
                      label: `${code.code} - ${code.text}`,
                    }));

                    return {
                      value: repairCode.name,
                      label: repairCode.name,
                      children: children,
                    };
                  }
                )}
                expandTrigger="hover"
                displayRender={displayRender}
                value={repairInformationDetails?.issueDiscovered}
                // defaultValue={["Moisture Indicator"]}
                onChange={(e) => {
                  updateInputv2(
                    e,
                    "issueDiscovered",
                    setrepairInformationDetails,
                    false
                  );
                }}
              />
            </div>
            <div>
              <p>Repaired Performed</p>

              <Select
                onChange={(e) => {
                  let arrayValues = `[${e}]`;

                  updateInputv2(
                    JSON.parse(arrayValues),
                    "itemsRepaired",
                    setrepairInformationDetails,
                    false
                  );
                }}
                value={repairInformationDetails?.itemsRepaired?.map((a) =>
                  JSON.stringify(a)
                )}
                mode="multiple"
                style={{ width: "400px", marginBottom: "10px" }}
                placeholder="Please select the repaired items"
              >
                {repairList?.map((repairItem, index) => (
                  <Select.Option
                    title={repairItem?.details}
                    key={index}
                    value={JSON.stringify(repairItem)}
                  >
                    {repairItem?.description} - {repairItem?.details}
                  </Select.Option>
                ))}
              </Select>
            </div>
            {/* <Collapse defaultActiveKey={["1"]}>
              <Collapse.Panel header="Repaired Items" key="1">

                  

                <DataTable
                  
                  selectableRows
                  onSelectedRowsChange={e => { updateInputv2(e.selectedRows,"itemsRepaired",setrepairInformationDetails,false) }}
                  title="Select Repaired Items"
                  pagination
                  data={repairList}
                  selectableRowSelected={row=> repairInformationDetails?.itemsRepaired?.find(item=> item.description===row?.description)}
                  columns={[{ name: "Description", selector: row => row?.description },
                  { name: "Details", selector: row => row?.details, cell: row => <p>{row?.details}</p> },
                  { name: "Cost", selector: row => row?.cost }
                  ]}

                />
              </Collapse.Panel>
            </Collapse> */}
          </Collapse.Panel>
        </Collapse>
      </Modal>
    </>
  );
};

const ChangeRFQStatus = ({ status, handleChange }) => {
  const getDisplayName = (status) => {
    if (status === "REPARING")
      return { text: "COMPLETE RFQ", color: "success", status: "COMPLETED" };
    else if (status === "COMPLETED")
      return { text: "RE-OPEN REPAIRS", color: "warning", status: "REPARING" };
    else return null;
  };

  const confirm = () => {
    let statusToChange = getDisplayName(status);
    if (statusToChange.status) handleChange(statusToChange.status);
  };

  const cancel = () => {};
  return (
    <>
      {getDisplayName(status) && (
        <>
          <Popconfirm
            // title={getDisplayName(status).text}
            title={`Are you sure to ${getDisplayName(status).text} ?`}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
            placement="bottomRight"
          >
            <Button
              variant={getDisplayName(status)?.color}
              size="sm"
              style={{ margin: "10px" }}
            >
              <WarningFilled /> {getDisplayName(status).text}
            </Button>
          </Popconfirm>
        </>
      )}
    </>
  );
};

const RFQSCSRList = ({ scsrs }) => {
  return (
    <>
    
      <DataTable
        data={scsrs}
        pagination
        columns={[{ name: "SN", selector: (row) => row?.serialNumber,grow:0 },
        { name: "Status", selector: (row) => (row?.status?.toUpperCase()) }
      ]}
      ></DataTable>
    </>
  );
};


const DownloadRFQReports = ()=>{

  const [dateRange, setdateRange] = useState();

  const search = ()=>{

    httpClient.get(`/api/v1/RFQ/report?from=${dateRange[0]}&to=${dateRange[1]}`).then(response=>{

      Modal.info({
        title: "RFQ Report Download",
        content: (
          <p>
            The RFQ report is beeing proccess you will receive it on your email as soon as its ready.
          </p>
        ),
      });

    }).catch(err=>{
      Modal.error({
        title: "RFQ Report Download",
        content: (
          <p>
            {err?.response?.data || err?.message} - 
            Fail to proccess the RFQ report , please contact your admin.
          </p>
        ),
      });
    })
  }
  
  return (<>
  <div style={{marginTop:"10px", marginBottom:"10px"}}>
    <div>Repairs Report : </div>
  <RangePicker
  format={"YYYY-MM-DD"}
          style={{ margin: "2px" }}
          onChange={(e) => {
            
            setdateRange(e)
          }}
        />{" "}
        <Button
          disabled={!dateRange}
          onClick={(e) => search(dateRange)}
          size="sm"
          style={{ margin: "2px" }}
        >
          Proccess
        </Button>
  </div>
  
  </>)
}
