import { message } from 'antd';
import axios from 'axios';
import { getObject } from './storage.service';

let authorizationErrorCOunt=0;

const axiosInstace = axios.create({baseURL:""});


axiosInstace.interceptors.request.use(async (config) => {
    const accessToken =  getObject("jwt_token"); 

    return {
        ...config,
        headers: {
          ...config.headers,
          "auth-x": accessToken?.jwt || ""
        }
      };
}, (error) =>
Promise.reject(error));

axiosInstace.interceptors.response.use((response) => {authorizationErrorCOunt=0;return response;}, (error) => {

    console.log(error?.response);
    
    if (error.response.status === 401) {
      window.location = '/app/login';

      authorizationErrorCOunt++;
      console.log(authorizationErrorCOunt,error?.response)
    }

    if (error.response.status === 403) {
      
      message.warn('Your dont have access to this resource , please contact your admin !');
      
      if(authorizationErrorCOunt> 10)
      {
        //window.location = '/app/login';

      }
     
      authorizationErrorCOunt++;
      console.log(authorizationErrorCOunt,error?.response)
    }

    return Promise.reject(error);
  });


const SetAuthorizationHeader = (value)=> {
   
    axiosInstace.defaults.headers.common["auth-x"]=value;
}



export const httpClient={
    get:axiosInstace.get,
    post:axiosInstace.post,
    put:axiosInstace.put,
    delete:axiosInstace.delete,
    SetAuthorizationHeader,
    axiosInstace
}