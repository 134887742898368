import {
  Checkbox,
  Descriptions,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Switch,
  Spin,
  Tabs
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import {
  Check2Circle,
  Download,
  Gear,
  GearFill,
  InfoCircle,
  InfoCircleFill,
  Printer,
} from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { json, Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import PageHeader, { DisplayDivColor } from "../components/PageHeader";
import Spinner, {
  errorModal,
  ShowConfirmation,
  successModal,
} from "../components/Spinner";
import { httpClient } from "../services/http.service";
import _ from "lodash";

import { useReactToPrint } from "react-to-print";
import { DisplayBasedOnRole } from "../components/DisplayBasedOnRole";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ReactSignatureCanvas from "react-signature-canvas";
import SearchBar, { SearchBarWithTimeRange, SearchMinesWithDateRange } from "../components/SearchBar";
import { AutiTrailInscpetions } from "../components/AutiTrail";

const { Option } = Select;

const statusStyle = [
  { text: "FAILED", style: { color: "red" } },
  { text: "PASSED", style: { color: "green" } },
  { text: "BUSY", style: { color: "red" } },
  { text: "SUBMITED", style: { color: "orange" } },
  { text: "CANCELED", style: { color: "red" } },
  { text: "COMPLETED", style: { color: "green" } },
];

function CompleteSCSRInspection({
  inspectionId,
  status,
  reload,
  inspectionsInvalid,
  disabled,
  setIsLoading
}) {
  var refSign = {};

  const [isBusy, setisBusy] = useState(false);

  const askForSignatureAndElse = () => {
    ShowConfirmation(
      "Complete inspection",
      <>
        <p>Please sign below to complete this implection.</p>
        <div
          style={{ border: "2px solid black", width: "100%", height: "100%" }}
        >
          <ReactSignatureCanvas
            penColor="#b6181c"
            ref={(re) => {
              refSign = re;
            }}
            canvasProps={{ height: "auto", width: "auto" }}
          />

          <div></div>
        </div>
      </>,
      handleClick
    );
  };

  const handleClick = () => {
    setisBusy(true);
    setIsLoading(true);
    httpClient
      .put(`/api/v1/inspections/${inspectionId}/complete`, {
        approverSignature: refSign.toDataURL(),
      })
      .then((response) => {})
      .then((err) => {
        setIsLoading(false);
        successModal(
          "Result",
          <div style={{ textAlign: "center" }}>
            <h4>
              This inspection has been completed, the mine will now have access
              to the inspection report.
            </h4>
          </div>
        );

        if (reload()) reload();
      })
      .catch((error) => {
        setisBusy(true);
        setIsLoading(false);
        message.error(error?.response?.data?.message || error);
      });
  };

  return (
    <>
      <Button
       
        variant="success"
        onClick={askForSignatureAndElse}
        disabled={disabled}
        size="sm"
        style={{ margin: "10px" }}
      >
        <Check2Circle /> Complete Inspection
      </Button>
    </>
  );
}
export function CreateMissingSCSR(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isBusy, setisBusy] = useState(false);

  const [scsrToFix, setscsrToFix] = useState([...(props?.inspections || [])]);

  useEffect(() => {
    setscsrToFix([...props.inspections]);
  }, [props.inspections]);
  

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createSCSRs = () => {
    setisBusy(true);

    let mypayLoad = {
      mineId: props?.mine?._id,

      scsrs: props?.inspections?.map((inspection) => ({
        serialNumber: inspection?.serialNumber,
        mineNumber: inspection?.mineNumber,
        model: inspection?.model,
        manufacturingDate: inspection.manufacturingDate,
        statusResult: inspection?.statusSerialValidity,
        replacementComment: inspection?.replacementComment,
        replacementOption: inspection?.replacementOption,
      })),
    };

    // var bodyFormData = new FormData();

    // bodyFormData.append("details",JSON.stringify(mypayLoad));

    httpClient
      .post(
        `/api/v1/inspections/${props?.inspectionId}/createbulkscsr`,
        mypayLoad
      )
      .then((res) => {
        const { data } = res;
        setisBusy(true);

        if (props.reload()) props.reload();

        successModal(
          "Result",
          <div style={{ textAlign: "center" }}>
            <h4>All missing SCSRs have been created successfully !</h4>
          </div>
        );
      })
      .catch((err) => {
        setisBusy(false);
        errorModal(
          `Failed SCSR Creation`,
          <>{JSON.stringify(err?.response?.data || err?.message)}</>
        );
      });
  };
  return (
    props?.inspections?.length > 0 && (
      <>
        <Button size="sm" onClick={showModal}>
          <InfoCircleFill /> Fix Issues With SCSRs
        </Button>
        <Modal
          footer={[
            <Button disabled={isBusy} onClick={() => createSCSRs()} size="sm">
              Create
            </Button>,
          ]}
          width={1200}
          title="Create non-existent SCSRs"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {props?.inspections?.length > 0 && 
           <DataTable
           pagination
           
           columns={[
             {
               
               compact: true,
               sortable: true,
               name: "Status",
               selector: (row) => row?.statusSerialValidity,
               cell: (row) => (
                 <>
                   {row?.statusSerialValidity === "VALID" && (
                     <div style={{ color: "green" }}>VALID</div>
                   )}
                   {row?.statusSerialValidity !== "VALID" && (
                     <div style={{ color: "red" }}>
                       {row?.statusSerialValidity}
                     </div>
                   )}
                 </>
               ),
               grow: 3,
             },
             {
               compact: true,
               name: "Inspected Date",
               selector: (row) => row?.inspectedDate,
               sortable: true,
               cell: (row) => (
                 <>{moment(row?.inspectedDate).format("YYYY-MM-DD HH:mm")}</>
               ),
               grow: 3,
             },
             {
               compact: true,
               name: "Serial Number",
               selector: (row) => row?.serialNumber,
               sortable: true,
               grow: 2,
               cell: (row) => (
                 <>
                   {" "}
                   {row?.id && (
                     <Link to={`/app/scsrs/${row?.id}`}>
                       {row?.serialNumber}
                     </Link>
                   )}
                   {!row?.id && <>{row?.serialNumber}</>}
                 </>
               ),
             },
             {
               compact: true,
               name: "Mine Number",
               selector: (row) => row?.mineNumber,
               sortable: true,
               grow: 2,
             },
             {
               compact: true,
               name: "Manufacturing Date",
               selector: (row) => row?.manufacturingDate,
               sortable: true,
               grow: 2,
             },

             {
               compact: true,
               sortable: true,
               name: "Result",
               selector: (row) => row.status,
               cell: (row) => (
                 <>
                   {" "}
                   <DisplayDivColor text={row?.status} values={statusStyle} />
                 </>
               ),
               grow: 2,
             },
             {
               sortable: true,
               name: "Code",
               selector: (row) => row?.code?.code,
               cell: (row) => (
                 <>
                   <p>
                     ({row?.code?.code}) {row?.code?.text}
                   </p>
                 </>
               ),
               grow: 2,
             },
             {
               compact: true,

               name: "Comments",
               selector: (row) => row?.comments,
               cell: (row) => <p>{row?.comments}</p>,
               grow: 2,
             },

             {
               name: "Pictures",
               cell: (row) => (
                 <p>
                   {/* <Image
                                       preview={{ visible: false }}
                                       width={50}
                                       style={{ margin: "10px" }}
                                       src={`/api/images/${row?.pictures[0].name}`}
                                       onClick={() => setVisible(true)}
                                   /> */}
                   <Image.PreviewGroup>
                     {row?.pictures?.map((picture) => (
                       <Image
                         width={"30px"}
                         src={`/api/images/${picture?.name}`}
                       />
                     ))}
                   </Image.PreviewGroup>
                 </p>
               ),
               grow: 2,
             },
             {
               grow: 4,
               selector: (row) => row?.statusSerialValidity,
               sortable: true,
               name: "Options",
               cell: (row) =>
                 row?.statusSerialValidity === "POSSIBLE REPLACEMENT" && (
                   <p style={{ marginBottom: "20px" }}>
                     {row?.replacementComment?.length > 5 && (
                       <Radio.Group
                         defaultValue={"replace_hold"}
                         onChange={(e) => {
                           let find = scsrToFix?.find(
                             (scsr) => scsr?.serialNumber === row?.serialNumber
                           );

                           let newArray = scsrToFix?.map((scsrMap) => {
                             let temp = scsrMap;
                             if (scsrMap?.serialNumber === row?.serialNumber)
                               temp["replacementOption"] = e.target.value;

                             return temp;
                           });

                           setscsrToFix([...newArray]);
                         }}
                         value={row?.replacementOption}
                       >
                         <Radio value={"replace"}>
                           Create & Replace Mine#
                         </Radio>
                         <Radio value={"replace_hold"}>
                           Create & Hold Replacement
                         </Radio>
                       </Radio.Group>
                     )}

                     <Input.TextArea
                       rows={2}
                       value={row?.replacementComment}
                       placeholder="Replacement Reason..."
                       onChange={(e) => {
                         let find = scsrToFix?.find(
                           (scsr) => scsr?.serialNumber === row?.serialNumber
                         );

                         let newArray = scsrToFix?.map((scsrMap) => {
                           let temp = scsrMap;
                           if (scsrMap?.serialNumber === row?.serialNumber)
                             temp["replacementComment"] = e.target.value;

                           return temp;
                         });

                         setscsrToFix([...newArray]);
                       }}
                     ></Input.TextArea>
                   </p>
                 ),
             },
           ]}
           data={scsrToFix}
         />
          }
         
        </Modal>
      </>
    )
  );
}

const DisplayColumChart = (props) => {
  const data = [
    {
      name: "2018",
      count: 20,
    },
  ];

  return (
    <>
      <div style={{ width: "100%", height: "300px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={props.data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis dataKey={"count"} />
            <Tooltip />
            {/* <Legend /> */}

            <Bar dataKey="count" fill="#b6181c" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export function InspectionDetails() {
  const { id } = useParams();
  const [isLoading, setisLoading] = useState(false);

  const [ispectionsDetails, setispectionsDetails] = useState();

  const [inspectionsStats, setinspectionsStats] = useState([]);
  const [inspectionsErrorStats, setinspectionsErrorStats] = useState([]);

  const [scsrExpirationYears, setscsrExpirationYears] = useState({});

  const [visible, setVisible] = useState(false);

  const [selectedSCSRs, setselectedSCSRs] = useState([]);

  const [scsrAuditTrai, setscsrAuditTrai] = useState([]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const loadInformation = () => {
    setisLoading(true);
    setselectedSCSRs([]);
    httpClient
      .get(`/api/v1/inspections/${id}`)
      .then((response) => {
        const { data } = response;
        setispectionsDetails({ ...data });
        setisLoading(false);

        let group = _.groupBy(data?.inspections, "status");

        let stats = Object.getOwnPropertyNames(group)?.map((statsName) => ({
          name: statsName,
          count: group[statsName].length,
        }));

        let erros = [];

        data?.inspections?.map((inspection) => {
          erros?.push(inspection?.code);
        });

        let errrosGroups = _.groupBy(erros, "code");
        let errosGroupStats = Object.getOwnPropertyNames(errrosGroups)?.map(
          (statsName) => ({
            name: statsName,
            count: errrosGroups[statsName].length,
            text: errrosGroups[statsName][0].text,
          })
        );

        let scsrGroupByExpirationDate = _.groupBy(
          data?.inspections,
          "warrantyExpirationyYear"
        );

        scsrGroupByExpirationDate = Object.getOwnPropertyNames(
          scsrGroupByExpirationDate
        )?.map((expiration) => ({
          name: expiration,
          count: scsrGroupByExpirationDate[expiration].length,
        }));

        setscsrExpirationYears([...scsrGroupByExpirationDate]);

        setinspectionsStats([...stats]);

        setinspectionsErrorStats([...errosGroupStats]);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    loadInformation();
    // let intervalId = setInterval(() => {
    //     loadInformation();
    // }, 2000);
    return () => {
      // clearInterval(intervalId)
    };
  }, [setselectedSCSRs]);

  const updateIncludingHandler = (inspectionId,payload)=>{

    setisLoading(true);
    httpClient.put(`/api/v1/inspections/${inspectionId}/include`,payload).then(response=>{
      loadInformation();
      setisLoading(false);
    }).catch(err=>{
      setisLoading(false);
    })
  }

  const updateInspectionStatus = (scsrcToInspect)=>{
    setisLoading(true);
    httpClient.put(`/api/v1/inspections/${id}/REOPENED`,{
      scsrcToInspect:scsrcToInspect
    }).then(response=>{
      // Modal.success({title:"Status Change",content:<><h4>Inspection Successfully Reopened !</h4></>});
      setisLoading(false);
      loadInformation();
    }).catch(err=>{
      setisLoading(false);
    })
  }

  const verifieInspectionCanBeCompleted = ()=>{
    let count = ispectionsDetails?.inspections?.filter(inspection=> inspection?.statusSerialValidity==="VALID");
    let countInvalid = ispectionsDetails?.inspections?.filter(inspection=> inspection?.statusSerialValidity!=="VALID" && inspection?.statusSerialValidity!=="DOES NOT EXIST");
    let issueButExist = ispectionsDetails?.inspections?.filter(inspection=> inspection?.statusSerialValidity==="DOES NOT EXIST" && inspection?.dontInclude);
    
    let run = {total:ispectionsDetails?.inspections?.length, totalValid:count?.length, invalidRemoved: countInvalid?.length, issueButExist:issueButExist?.length};
   

    return (run.total - (run.invalidRemoved+run.totalValid+run.issueButExist))===0
  
  }

  const loadscsrAuditTrail = (e, mode,documentType="SCSR") => {
    setisLoading(true);
   
    let daterange = e;
    httpClient
      .get(
        `/api/v1/scsr/${id}/audittrail?from=${daterange[0]}&to=${daterange[1]}&mode=${mode}&documentType=${documentType}`       
      )
      .then((response) => {
        const { data } = response;
        setscsrAuditTrai([...data]);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };


  return (
    <>
      <Header />
      <PageHeader title={"Inspections Details"} />

      <Spin spinning={isLoading} size="large">
      <Container>
       
      <Tabs  defaultActiveKey="1" >
        <Tabs.TabPane key={"1"}  tab="Inspection Details">
        <DisplayBasedOnRole roles={["Admin"]}>
       <PrintSummaryInspectionResult
         isBusy={isLoading}
         setisBusy={setisLoading}
         {...ispectionsDetails}
         buttonTitle={"Download Summany"}
       />
         </DisplayBasedOnRole>
      
       <div style={{ margin: "20px" }} ref={componentRef}>
         <Descriptions colon={1} title={"Inspection Details"} bordered>
           <Descriptions.Item label="Mine">
             <div style={{ fontWeight: "bold" }}>
               {ispectionsDetails?.mine?.name}
             </div>
           </Descriptions.Item>
           <Descriptions.Item label="Creation Date">
             {" "}
             {ispectionsDetails?.creationDate
               ? moment(ispectionsDetails?.creationDate).format(
                   "YYYY-MM-DD HH:mm"
                 )
               : "-"}
           </Descriptions.Item>
           <Descriptions.Item label="Quater">
             <div style={{ fontWeight: "bold" }}>
               {ispectionsDetails?.quater}
             </div>
           </Descriptions.Item>
           <Descriptions.Item label="Inspected By">
             <div style={{ fontWeight: "bold" }}>
               {ispectionsDetails?.user?.name}{" "}
               {ispectionsDetails?.user?.surname}
             </div>
           </Descriptions.Item>
           <Descriptions.Item style={{ color: "green" }} label="SCSRs to be tested">
             {ispectionsDetails?.scsrcToInspect}
           </Descriptions.Item>
           <Descriptions.Item
             style={{ color: "orange" }}
             label="SCSRs Inspected"
           >
             {ispectionsDetails?.inspections?.length}
           </Descriptions.Item>
           <Descriptions.Item style={{ color: "red" }} label="SCSRs Remaining">
             {ispectionsDetails?.scsrcToInspect -
               ispectionsDetails?.inspections?.length}
           </Descriptions.Item>
           <Descriptions.Item label="Last Update">
             {" "}
             {ispectionsDetails?.creationDate
               ? moment(ispectionsDetails?.lastUpdated).format(
                   "YYYY-MM-DD HH:mm"
                 )
               : "Not Synch Yet"}
           </Descriptions.Item>
           <Descriptions.Item label="Status">
             <div style={{ fontWeight: "bold" }}>
               {" "}
               <DisplayDivColor
                 text={ispectionsDetails?.status}
                 values={statusStyle}
               />
             </div>
           </Descriptions.Item>
         </Descriptions>
         <Descriptions title={"Inspection Stats"} column={4} bordered>
           {inspectionsStats?.map((stat) => (
             <Descriptions.Item label={stat?.name}>
               {stat?.count}
             </Descriptions.Item>
           ))}
         </Descriptions>
         <Descriptions title={"Erros Stats"} column={4} bordered>
           {inspectionsErrorStats?.map((stat) => (
             <Descriptions.Item label={stat?.count}>
               <p>
                 ({stat?.name}) {stat?.text}
               </p>
             </Descriptions.Item>
           ))}
         </Descriptions>
         {}

         <Descriptions
           title={"SCSR Expiration Year"}
           style={{ marginTop: "10px" }}
           column={4}
           bordered
         >
           {/* {scsrExpirationYears?.map(stat => <Descriptions.Item label={stat?.name}>{stat?.count}</Descriptions.Item>)} */}
         </Descriptions>

         {ispectionsDetails?.inspections && (
           <DisplayColumChart data={scsrExpirationYears} />
         )}

         <Descriptions colon={1} title={"SCSRs"}></Descriptions>
         {/* <Button size="sm" style={{margin:"1px"}} onClick={()=>{
                   httpClient.put(`/api/v1/inspections/${id}/sync`)
                   .then(response=>{
                       successModal("Inspection Sync");
                       loadInformation();
                   })
                   .catch(err=>{
                       errorModal("Failed Inspection Sync",<>
                       {JSON.stringify(err?.response?.data || err?.message)}
                       </>)
                   })
               }}>Sycn Inspection</Button> */}

         <DisplayBasedOnRole roles={["Admin"]}>
           {ispectionsDetails?.inspections && (
             <CreateMissingSCSR
               reload={loadInformation}
               inspectionId={id}
               hidden={false}
               mine={ispectionsDetails?.mine}
               inspections={selectedSCSRs?.filter(
                 (filterInspection) =>
                   filterInspection?.statusSerialValidity ===
                     "DOES NOT EXIST" ||
                   filterInspection?.statusSerialValidity ===
                     "POSSIBLE REPLACEMENT"
               )}
             />
           )}
           
           {ispectionsDetails?.status!="COMPLETED" && 
            <CompleteSCSRInspection
            setIsLoading={(e)=> setisLoading(e)}
            disabled={!verifieInspectionCanBeCompleted()}
            inspectionsInvalid={ispectionsDetails?.inspections?.filter(
              (filterInspection) =>
                filterInspection?.statusSerialValidity === "DOES NOT EXIST"
            )} 
            inspectionId={id}
            reload={loadInformation}
            status={ispectionsDetails?.status}
          />
           }
          

           {/* <RemoveItemFromInspection /> */}
           <ChangeInspectionStatusOdSCSR handleOk={data=>{
            // alert(JSON.stringify(data));
            
             let moredata =ispectionsDetails?.inspections?.map(dataline=> {
               let find =  data?.find(dataoriginal=> dataoriginal?.serialNumber===dataline?.serialNumber);
               
               if(find)
               {
                 
                 return find;
               }
               else
               return dataline;
             
             })
            
             updateIncludingHandler(id,moredata)
             
           }} selectedRows={selectedSCSRs} />
           
           <ReopenedInspection  scsrcToInspectCount={ispectionsDetails?.scsrcToInspect} updateInspectionStatus={updateInspectionStatus} isLoading={isLoading}  />
           {/* <Button disabled={isLoading } onClick={updateInspectionStatus}  size="sm" color="danger"><GearFill/> Reopen Inspection</Button> */}
         </DisplayBasedOnRole>
        
         <DataTable
           selectableRows
           columns={[
             {
               sortable: true,
               name: "Status",
               selector: (row) => row?.statusSerialValidity,
               cell: (row) => (
                 <>
                   {row?.statusSerialValidity === "VALID" && (
                     <div style={{ color: "green" }}>VALID</div>
                   )}
                   {row?.statusSerialValidity !== "VALID" && (
                     <div style={{ color: "red" }}>
                       {row?.statusSerialValidity}
                     </div>
                   )}
                 </>
               ),
               grow: 2,
             },
             {
               name: "Inspected Date",
               selector: (row) => row?.inspectedDate,
               sortable: true,
               cell: (row) => (
                 <>{moment(row?.inspectedDate).format("YYYY-MM-DD HH:mm")}</>
               ),
               grow: 2,
             },
             {
               name: "Serial Number",
               selector: (row) => row?.serialNumber,
               sortable: true,
               grow: 2,
               cell: (row) => (
                 <>
                   {" "}
                   {row?.id && (
                     <Link to={`/app/scsrs/${row?.id}`}>
                       {row?.serialNumber}
                     </Link>
                   )}
                   {!row?.id && <>{row?.serialNumber}</>}
                 </>
               ),
             },
             {
               sortable: true,
               name: "Result",
               selector: (row) => row.status,
               cell: (row) => (
                 <>
                   {" "}
                   <DisplayDivColor
                     text={row?.status}
                     values={[
                       { text: "FAILED", style: { color: "red" } },
                       { text: "PASSED", style: { color: "green" } },
                     ]}
                   />
                 </>
               ),
               grow: 2,
             },
             {
               sortable: true,
               name: "Code",
               selector: (row) => row?.code?.code,
               cell: (row) => (
                 <p>
                   ({row?.code?.code}) {row?.code?.text}
                 </p>
               ),
               grow: 2,
             },
             {
               name: "Comments",
               selector: (row) => row?.comments,
               cell: (row) => <>
               <p>
               <p>
                 {row?.comments}
                
                 </p>

                 
                 {row?.dontInclude && 
                 
                 <p style={{color:"red"}}>
                   
                   {row?.dontInclude}
                   </p>}
               </p>
              
               </>
                 ,
               grow: 2,
             },
             {
               name: "Pictures",
               cell: (row) => (
                 <p>
                   {/* <Image
                                   preview={{ visible: false }}
                                   width={50}
                                   style={{ margin: "10px" }}
                                   src={`/api/images/${row?.pictures[0].name}`}
                                   onClick={() => setVisible(true)}
                               /> */}
                   <Image.PreviewGroup>
                     {row?.pictures?.map((picture) => (
                       <Image
                         width={"30px"}
                         src={`/api/images/${picture?.name}`}
                       />
                     ))}
                   </Image.PreviewGroup>
                 </p>
               ),
               grow: 2,
             },
           ]}
           data={ispectionsDetails?.inspections}
           onSelectedRowsChange={rows=>{
               setselectedSCSRs(rows.selectedRows)
              
           }}
         />

         <Descriptions style={{ marginTop: "30px" }} column={2} title={""}>
           <Descriptions.Item label="Inspected By">
             <div style={{ textAlign: "center" }}>
               <div style={{ fontWeight: "bold" }}>
                 {ispectionsDetails?.user?.name}{" "}
                 {ispectionsDetails?.user?.surname}
               </div>
               <div>
                 {" "}
                 <Image
                   width={"150px"}
                   height={"150px"}
                   src={ispectionsDetails?.inspectionSingature}
                 />
               </div>
             </div>
           </Descriptions.Item>
           {ispectionsDetails?.approverUser && (
             <Descriptions.Item label="Approved By">
               <div style={{ textAlign: "center" }}>
                 <div style={{ fontWeight: "bold" }}>
                   {ispectionsDetails?.approverUser?.name}{" "}
                   {ispectionsDetails?.approverUser?.surname}
                 </div>
                 <div>
                   {" "}
                   <Image
                     width={"150px"}
                     height={"150px"}
                     src={ispectionsDetails?.approverSignature}
                   />
                 </div>
               </div>
             </Descriptions.Item>
           )}
         </Descriptions>
       </div>
        </Tabs.TabPane>

        <Tabs.TabPane key={"2"} tab="Audit Trail">
          <AutiTrailInscpetions scsrAuditTrai={scsrAuditTrai} loadscsrAuditTrail={loadscsrAuditTrail} />
        </Tabs.TabPane>
      </Tabs>

       
     </Container>
      </Spin>

     
    </>
  );
}

export default function Inspections() {
  const [isLoading, setisLoading] = useState(false);

  const [inspections, setinspections] = useState();

  const navigate = useNavigate();

  const loadInspections = () => {
    setisLoading(true);
    httpClient
      .get("/api/v1/inspections")
      .then((response) => {
        const { data } = response;
        setinspections([...data]);
        setisLoading(false);
      })
      .catch((err) => setisLoading(false));
  };

  const loadInspectionsSearchMines = (mine, from , to) => {
    setisLoading(true);
    httpClient
      .get(`/api/v1/inspections?mine=${mine}&from=${from}&to=${to}`)
      .then((response) => {
        const { data } = response;
        setinspections([...data]);
        setisLoading(false);
      })
      .catch((err) => setisLoading(false));
  };

  useEffect(() => {
    loadInspections();
    return () => {};
  }, []);

  return (
    <>
      {" "}
      <Header />
      <PageHeader title={"Inspections"} />
     <Container>
     <DisplayBasedOnRole roles={["Admin","OEM"]}>
          <SearchMinesWithDateRange
            search={(date, mine,mode) =>
              loadInspectionsSearchMines(mine, date[0], date[1],mode)
            }
          />
        </DisplayBasedOnRole>
     
     <Spin size="large" spinning={isLoading}>
     <DataTable
          pagination
          columns={[
            {
              compact: true,
              name: "Creation Date",
              selector: (row) => row.creationDate,
              sortable: true,
              cell: (row) => (
                <p>{moment(row?.creationDate).format("YYYY-MM-DD HH:mm")}</p>
              ),
              grow: 2,
            },
            {
              compact: true,
              name: "Mine Name",
              selector: (row) => row?.mine?.name,
              sortable: true,
              grow: 2,
              cell: (row) => <p>{row?.mine?.name}</p>,
            },
            {
              compact: true,
              sortable: true,
              name: "SCSRs",
              selector: (row) => row.scsrcToInspect,
              cell: (row) => <p>{row.scsrcToInspect}</p>,
              grow: 2,
            },
            {
              compact: true,
              name: "Last Synch",
              selector: (row) => row.lastUpdated,
              sortable: true,
              cell: (row) => (
                <p>
                  {row?.lastUpdated
                    ? moment(row?.lastUpdated).format("YYYY-MM-DD HH:mm")
                    : "Not Synch Yet"}
                </p>
              ),
              grow: 2,
            },

            {
              compact: true,
              sortable: true,
              name: "SCSRs Inspected",
              selector: (row) => row?.inspectionsCount,
              cell: (row) => (
                <p style={{ color: "orange" }}>{row?.inspectionsCount}</p>
              ),
              grow: 2,
            },
            {
              compact: true,
              sortable: true,
              name: "SCSR Remaining",
              selector: (row) =>
                row.scsrcToInspect - (row?.inspectionsCount || 0),
              cell: (row) => (
                <p>{row.scsrcToInspect - (row?.inspectionsCount || 0)}</p>
              ),
              grow: 2,
            },
            {
              compact: true,
              sortable: true,
              name: "Inspected By",
              selector: (row) => row?.user?._id,
              cell: (row) => (
                <p>
                  {row?.user?.name} {row?.user?.surname}
                </p>
              ),
              grow: 2,
            },
            {
              compact: true,
              sortable: true,
              name: "Status",
              selector: (row) => row?.status,
              cell: (row) => (
                <p>
                  {" "}
                  <DisplayDivColor text={row?.status} values={statusStyle} />
                </p>
              ),
              grow: 2,
            },
            {
              name: "",

              cell: (row) => (
                <>
                  <Button
                    onClick={() => {
                      navigate(`/app/inspections/${row?._id}`);
                    }}
                    size="sm"
                  >
                    <InfoCircle /> Details
                  </Button>
                </>
              ),
              grow: 2,
            },
          ]}
          data={inspections}
        />
     </Spin>
        {/* <Spinner isLoading={isLoading} /> */}
   
      </Container>
    </>
  );
}

const PrintSummaryInspectionResult = (props) => {
  const [isBusy, setisBusy] = useState(false);

  const setIsbusyToParent = (value) => {
    if (props.setisBusy) props.setisBusy(value);
  };

  const donwload = (full) => {
    setisBusy(true);
    setIsbusyToParent(true);
    fetch(`/api/v1/inspections/${props._id}/download?full=${full?true:null}`).then((response) => {
      response
        .blob()
        .then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob); // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `Inspection Repport ${moment(
            props?.creationDate
          ).format("YYYY-MM-DD HHmm")}.pdf`;
          alink.click();
          setIsbusyToParent(false);
          setisBusy(false);
        })
        .catch((err) => {
          setIsbusyToParent(false);
          setisBusy(false);
        });
    });
  };

  return (
    <>
      {/* <Input maxLength={10}   style={{width:"350px", margin:"10px"}} value={invoiceNumber} addonBefore ="Invoice #" placeholder="Please enter an invoice number"  onChange={e=> setinvoiceNumber(e.target?.value?.toLocaleUpperCase())} /> */}
      <Button
        size="sm"
        disabled={isBusy || props?.status!=="COMPLETED"}
        onClick={donwload}
        style={{ margin: "10px" }}
      >
        {" "}
        <Download /> {props?.buttonTitle}
      </Button>

      <Button
        size="sm"
        disabled={isBusy || props?.status!=="COMPLETED"}
        onClick={()=> donwload(true)}
        style={{ margin: "10px" }}
      >
        {" "}
        <Download /> Download Full Report
      </Button>
    </>
  );
};

const RemoveItemFromInspection = () => {
  return (
    <>
      <Popconfirm
        title="Delete the task"
        description="Are you sure to delete this task?"
        okText="Yes"
        cancelText="No"
      >
        <Button size="sm" style={{ margin: "20px" }}>
          Remove From Inspection
        </Button>
      </Popconfirm>
    </>
  );
};

function ChangeInspectionStatusOdSCSR(props) {
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState("DO NOT INCLUDE");
  const [comments, setComments] = useState("");

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleOk = () => {
    console.log("Status:", status);
    console.log("Comments:", comments);
    setVisible(false);

    let newUpdate;

    if(status==="DO NOT INCLUDE")
    newUpdate = props.selectedRows?.map(selectedrow=>({...selectedrow,dontInclude:comments}));
    else
    newUpdate = props.selectedRows?.map(selectedrow=>({...selectedrow,dontInclude:null}));

    props?.handleOk(newUpdate)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    props.selectedRows.length>0 && 
    <>
      <Button size="sm" type="primary" onClick={() => setVisible(true)}>
        <Gear /> Omit From Inspection
      </Button>
      <Modal
        title="Change Result"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
         
          <label htmlFor="status-select">Status</label>
          <Select
            id="status-select"
            value={status}
            onChange={handleStatusChange}
            style={{ width: "100%" }}
          >
            
            <Option value="DO NOT INCLUDE">DO NOT INCLUDE</Option>
            <Option value="INCLUDE">INCLUDE</Option>
            
          </Select>
        </div>
        <div>
          <label htmlFor="comments-textarea">Comments</label>
          <Input.TextArea
            id="comments-textarea"
            value={comments}
            onChange={handleCommentsChange}
            autoSize={{ minRows: 3 }}
          />
        </div>
      </Modal>
    </>
  );
}

function ReopenedInspection ({isLoading,updateInspectionStatus,scsrcToInspectCount})
{

  const [openModal, setopenModal] = useState(false);
  const [scsrToInespect, setscsrToInespect] = useState(scsrcToInspectCount)
  return(<>
  
   <Button style={{marginLeft:"10px", marginRight:"10px"}} disabled={isLoading } onClick={()=> setopenModal(true)}  size="sm" color="danger"><GearFill/> Reopen Inspection</Button>
    <Modal visible={openModal} title="Reopen Inspecton" onOk={()=> {setopenModal(false); updateInspectionStatus(scsrToInespect)}} onCancel={()=> setopenModal(false)}>
      <div>
        SCSRs to Inspect :
        <InputNumber defaultValue={scsrcToInspectCount} value={scsrToInespect} onChange={e=> setscsrToInespect(e)}></InputNumber>
      </div>
    </Modal>
  </>)
}
