import { Input } from 'antd'
import React, { useState } from 'react'
import { LoginUser } from '../services/auth.service'
import { defaultStyling, updateInputv2 } from '../utilities'
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';

// backgroundColor: ""

export default function Login() {
    const [userLoginDetails, setuserLoginDetails] = useState({});
    const [isBusy, setisBusy] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <div style={{ marginTop: 0 }}>
                <div style={{ height: "100vh", textAlign: "center" }}>
                    <img style={{ height: "230px", width: "230px" }} alt='iTeloma' src='/logo2.png'></img>
                    <form onSubmit={e => {
                        e.preventDefault();
                        setisBusy(true);
                        LoginUser(userLoginDetails?.email, userLoginDetails.password, (callback => {
                            setisBusy(false);
                            if (callback?.isSuccess)
                                navigate("/");

                        }))
                    }} style={{ padding: "50px", backgroundColor: "#415e9a", maxWidth: "500px", position: "absolute", left: "20px", right: "20px", marginRight: "auto", marginLeft: "auto", display: "grid", flexDirection: "column" }}>
                        <h1 style={{ color: "white", fontSize: "2rem" }}>Welcome to iTeloma!</h1>
                        <h1 style={{ color: "white", fontSize: "1.5rem" }}>Please Login.</h1>
                        <Input onChange={e => updateInputv2(e.target.value, "email", setuserLoginDetails)} style={defaultStyling.input} placeholder='Email' />
                        <Input onChange={e => updateInputv2(e.target.value, "password", setuserLoginDetails)} type="password" style={defaultStyling.input} placeholder='Password' />
                        <Button disabled={isBusy} type='submit' style={defaultStyling.button} >LOGIN</Button>
                        <Button  disabled={isBusy} style={defaultStyling.button} onClick={()=>{
                            // ResetUserPassword(userLoginDetails?.email);
                            navigate("/app/passwordresetrequest",{replace:true})
                        }} variant="dark">RESET PASSWORD</Button>
                    </form>
                </div>

            </div>
        </>
    )
}
