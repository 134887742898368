import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import confirm from 'antd/es/modal/confirm';
import React from 'react';
import RiseLoader from "react-spinners/RiseLoader";

export default function Spinner({isLoading}) {
  return (
    <div style={{margin:"30px", textAlign:"center"}}>
     <RiseLoader
        cssOverride={{borderWidth:"2px"}}
        color={"#b6181c"}
        loading={isLoading}       
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}


export const ShowConfirmation = (title,content,ok) => {
  confirm({
    title: title,
    icon: <ExclamationCircleFilled color='warning' />,
    content: content,
    width:500,
    okType:"danger",
    okText:"Complete Inspection",
    onOk() {
      if(ok)
      ok();
    },
    onCancel() {
      console.log('Cancel');
      
    },
  });
};

export const successModal = (title,contentValue) => {
  Modal.success({
    centered:true,
    title:title,
    content: contentValue,
    okButtonProps:{color:"red"}
  });
};

export const errorModal = (title,contentValue) => {
  Modal.error({
    centered:true,
    title:title,
    content: contentValue,
  });
};





