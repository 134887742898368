
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import MainMenu from './pages/MainMenu';

import "./pages/default.css";
import Users from './pages/Users';
import {  useState } from 'react';
import { getObject } from './services/storage.service';
import Mines from './pages/Mines';
import SCSR, { SCSRDetails } from './pages/SCSR';
import PaswordReset from './pages/PaswordReset';
import PasswordResetRequest from './pages/PasswordResetRequest';
import Inspections, { InspectionDetails } from './pages/Inspections';
import Repairs, { RepairRFQDetails } from './pages/Repairs';
import Reports from './pages/Reports';

// const Info=()=>{
//   return(<>
//   <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div></>)
// }

function SecureRoute ({children})
{
  const [userInformation] = useState({...getObject("jwt_token")});
  return (userInformation?.user ? children : <Navigate to={"/app/login"} replace />)
  
}

function App() {
  return (
    <BrowserRouter>
    <Routes>
      
      <Route path='/app/login' element={<Login/>}></Route>     
      <Route path='/app/passwordresetrequest' element={<PasswordResetRequest/>}></Route>    
      <Route path='/app/passwordreset/:code' element={<PaswordReset/>}></Route>     
            <Route path='/app/users' element={<SecureRoute><Users /></SecureRoute>}></Route>     
      <Route path='/app/mines' element={<SecureRoute><Mines /></SecureRoute>}></Route>    
      <Route path='/app/scsrs/:id' element={<SecureRoute><SCSRDetails /></SecureRoute>}></Route>    
      <Route path='/app/scsr' element={<SecureRoute><SCSR /></SecureRoute>}></Route>   
      <Route path='/app/inspections' element={<SecureRoute><Inspections  /></SecureRoute>}></Route>    
      <Route path='/app/inspections/:id' element={<SecureRoute><InspectionDetails  /></SecureRoute>}></Route>    
      <Route path='/app/repairs' element={<SecureRoute><Repairs  /></SecureRoute>}></Route>   
      <Route path='/app/reports' element={<SecureRoute><Reports  /></SecureRoute>}></Route>   
      <Route path='/app/repairs/:id' element={<SecureRoute><RepairRFQDetails  /></SecureRoute>}></Route>  
      
       
      <Route path='/' element={<SecureRoute><MainMenu/></SecureRoute>}></Route>
    </Routes>
    </BrowserRouter>
    
  );
}

export default App;
