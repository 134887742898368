import React from 'react'

export default function PageHeader({ title, subtitle }) {
  return (
    <div style={{ textAlign: "center" }}><h3>{title}</h3>
    <h4>{subtitle}</h4>
    </div>
  )
}

export function DisplayDivColor({values,text})
{

  const determineColor =()=>
  {
    let mycolor = values?.find(value=> value?.text === text);

    return <div style={{...mycolor?.style}}>{text}</div>
  }
 
  return(<>{determineColor()}
  </>)
}