
export  function storeData(key, data) {
  return localStorage.setItem(key,JSON.stringify(data));
}

export  function getObject(key) {
  
  let data;
  const ret =  localStorage.getItem(key);

  if(!ret)  data=null;
  else
  data = JSON.parse(ret);

  return data;
}

