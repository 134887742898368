import { message } from "antd";
import { httpClient } from "./http.service";
import { storeData } from "./storage.service";

export function LoginUser(email, password, callBack) {
  httpClient
    .post("/api/v1/auth", { email: email, password: password })
    .then((response) => {
      const { data } = response;
     
      message.success("Login was successfull!")
      storeData("jwt_token", data);
      httpClient.SetAuthorizationHeader(data.jwt);
      callBack({ isSuccess: true, data: data });
      
    })
    .catch((error) => {
      callBack({ isSuccess: false, data: error?.response?.data?.message || error });
      message.error(error?.response?.data?.message || error)
    });
}

export function ChangePassword(email, password, passwordConfirm, code, callBack) {
  httpClient
    .post("/api/v1/auth/passwordreset/email/changepassword", { email: email, password: password, password_confirmation:passwordConfirm, code:code })
    .then((response) => {     
     
      message.success("Password Changed Successfully !");
      
     window.location.replace("/app/login");
      
    })
    .catch((error) => {
      if(callBack)
      callBack()
      message.error(error?.response?.data || error)
    });
}

export function ResetUserPassword(email,callback) {
  httpClient
    .post("/api/v1/auth/passwordreset/email", { email: email })
    .then((response) => {    
     
      message.success("Reset password link sent to your email !");     
      if(callback) callback();
    })
    .catch((error) => {
      message.error(error?.response?.data || error)
      // message.error(error?.response?.data?.message || error)
    });
}

export function  LogoutUser(logoutCallBack)
{
    storeData("jwt_token",{}).catch(err=>{});
    httpClient.SetAuthorizationHeader("");
    if(logoutCallBack)
    logoutCallBack();
}

export async  function GetUsers() {
    return await httpClient.get("/api/v1/users");      
  }

  export function GetUserById(id,callBack) {
    httpClient
      .get(`/api/v1/users/${id}`)
      .then((response) => {
        const { data } = response;
        callBack(data);
      })
      .catch((error) => {
        callBack({ isSuccess: false, data: error?.response?.data || error });
      });
  }

  export function SearchGetUsers(seach,callBack) {
    httpClient
      .post("/api/v1/users/search",{search:seach})
      .then((response) => {
        const { data } = response;
        callBack(data);
      })
      .catch((error) => {
        callBack({ isSuccess: false, data: error?.response?.data || error });
      });

    
  }

  export function CreateUser(userDetails,callBack) {
    httpClient
      .post("/api/v1/users",{...userDetails})
      .then((response) => {
        const { data } = response;
        
        callBack(data);
      })
      .catch((error) => {
       
        callBack({ isSuccess: false, data: error?.response?.data.data || error });
      });

    
  }
  

  export async function ImportBulkUsers(file)
  {

    let formData = new FormData();
    formData.append('users', file, file.name);

    await httpClient.post('/api/v1/users/bulk',formData);
  }
