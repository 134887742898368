import {
  EditFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import {
  DatePicker,
  Descriptions,
  Form,
  Image,
  Input,
  message,
  Modal,
  Select,
  Tabs,
  Timeline,
  Spin
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import { BoxArrowInDown, Infinity } from "react-bootstrap-icons";

import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";

import { DisplayBasedOnRole } from "../components/DisplayBasedOnRole";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import SearchBar, { SearchBarWithTimeRange } from "../components/SearchBar";
import Spinner from "../components/Spinner";
import { httpClient } from "../services/http.service";
import { updateInputv2 } from "../utilities";

const { confirm } = Modal;

const CreateUpdeSCSR = (props) => {
  const [scsrDetails, setscsrDetails] = useState({});
  const [isModalOpen, setisModalOpen] = useState(false);

  const closeModalHandler = () => {
    setisModalOpen(false);
    setscsrDetails({});
  };

  const [isLoading, setisLoading] = useState(false);
  const [availableMines, setavailableMines] = useState([]);

  const loadSCSRInformatino = () => {
    if (!props._id) return;

    setisLoading(true);
    httpClient
      .get(`/api/v1/scsr/${props._id}`)
      .then((response) => {
        const { data } = response;
        setisLoading(false);
        setscsrDetails({ ...data, mineId: data?.mine?._id });
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const loadMines = () => {
    httpClient
      .get(`/api/v1/mines`)
      .then((response) => {
        const { data } = response;
        setavailableMines([...data]);
      })
      .catch((err) => {});
  };

  const udpate = () => [
    httpClient
      .put(`/api/v1/scsr/${props._id}`, {
        model: scsrDetails.model,
        serialNumber: scsrDetails.serialNumber,
        mineCode: scsrDetails.mineCode,
        warrantyExpiration: scsrDetails.warrantyExpiration,
        manufacturingDate: scsrDetails.manufacturingDate,

        mineNumber: scsrDetails.mineNumber,
        mine: scsrDetails?.mineId,
      })
      .then((response) => {
        //const {data}= response;
        message.success(`SCSR details updated !`);
        closeModalHandler();
        props.reload();
      })
      .catch((err) => {
        message.error(err.response.data || err);
      }),
  ];

  const create = () => {
    httpClient
      .post("/api/v1/scsr", {
        model: scsrDetails.model,
        serialNumber: scsrDetails.serialNumber,
        mineCode: scsrDetails.mineCode,
        warrantyExpiration: scsrDetails.warrantyExpiration,
        manufacturingDate: scsrDetails.manufacturingDate,
        status: scsrDetails.status,
        mineNumber: scsrDetails.mineNumber,
        mine: scsrDetails?.mineId,
      })
      .then((response) => {
        //const {data}= response;
        message.success(`SCSR created successfully !`);
        props.reload();
        closeModalHandler();
      })
      .catch((err) => {
        message.error(err.response.data || err);
      });
  };

  return (
    <>
      <Button
        onClick={() => {
          setisModalOpen(true);
          loadSCSRInformatino();
          loadMines();
        }}
        size="sm"
        style={{ margin: 2 }}
        variant={props?.color || "primary"}
      >
        {props?.title || <> </>}
      </Button>
      <Modal
        key={props._id}
        width={600}
        footer={[
          <Button
            size="sm"
            onClick={() => {
              if (props._id) udpate();
              else create();
            }}
          >
            Save
          </Button>,
        ]}
        title={props?.title}
        open={isModalOpen}
        onCancel={() => {
          closeModalHandler();
        }}
      >
        <Form
          disabled={isLoading}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          layout="horizontal"
        >
          <Form.Item label="Serial Number">
            <Input
              value={scsrDetails?.serialNumber}
              onChange={(e) =>
                updateInputv2(e.target.value, "serialNumber", setscsrDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Mine Number">
            <Input
              value={scsrDetails?.mineNumber}
              onChange={(e) =>
                updateInputv2(e.target.value, "mineNumber", setscsrDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Model">
            <Select
              value={scsrDetails?.model}
              onChange={(e) => updateInputv2(e, "model", setscsrDetails)}
            >
              <Select.Option value="ROXY 40">ROXY 40</Select.Option>
              <Select.Option value="AFROX PACK 35i">AFROX PACK 35i</Select.Option>
             
            </Select>
          </Form.Item>

          <Form.Item label="Warranty Expiration">
            <DatePicker
              disabled
              value={
                scsrDetails?.warrantyExpiration
                  ? moment(scsrDetails?.warrantyExpiration)
                  : null
              }
              onChange={(e) =>
                updateInputv2(e, "warrantyExpiration", setscsrDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Manufacturing Date">
            <DatePicker
              format={"MM/YYYY"}
              value={
                scsrDetails?.manufacturingDate
                  ? moment(scsrDetails?.manufacturingDate)
                  : null
              }
              onChange={(e) =>
                updateInputv2(e, "manufacturingDate", setscsrDetails)
              }
            />
          </Form.Item>

          <Form.Item label="Mine">
            <Select
              value={scsrDetails?.mineId}
              onChange={(e) => updateInputv2(e, "mineId", setscsrDetails)}
            >
              {availableMines?.map((availableMine) => (
                <Select.Option
                  key={availableMine?._id}
                  value={availableMine?._id}
                >
                  {availableMine?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const ImportSCSR = (props) => {
  var file;

  const [isModalOpen, setisModalOpen] = useState(false);

  const [isBusy, setisBusy] = useState(false);

  const handleOpenModal = () => {
    setisModalOpen(true);
  };

  const [resultDetails, setresultDetails] = useState([]);

  const handleCancel = () => {
    setisModalOpen(false);
    setresultDetails([]);
  };

  function ImportSCRS(file) {
    let formData = new FormData();
    formData.append("scsrs", file, file.name);

    setisBusy(true);

    httpClient
      .post("/api/v1/scsr/bulk", formData)
      .then((response) => {
        //   const { data } = response;

        message.success(`SCSR's imported successfully !`);
        setisBusy(false);
      })
      .catch((error) => {
        setisBusy(false);
        message.error(`Failed to upload SCSR's !`);
        setresultDetails(error?.response?.data || []);
      });
  }

  

  return (
    <>
   
      <Button
        onClick={handleOpenModal}
        size="sm"
        style={{ margin: 2 }}
        variant={props.color || "primary"}
      >
        {props.title || <> </>}
      </Button>
     
      <Modal
        width={600}
        footer={[
          <Button disabled={isBusy} onClick={() => ImportSCRS(file)}>{isBusy ? "Importing SCSRs ..." :"Import SCSR's"} </Button>,
        ]}
        title={props.title}
        open={isBusy || isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Input
            accept=".csv"
            type="file"
            onChange={(e) => {
              file = e.target.files[0];
              console.log(`File`, file);
            }}
          />

          {resultDetails?.map((resul) => (
            <p
              key={resul?.name}
              style={{ color: resul?.failed ? "red" : "green" }}
            >
              {resul?.name}, {resul?.message}{" "}
            </p>
          ))}
        </Form>
      </Modal>
    
     
    </>
  );
};

export function SCSRDetails() {
  const { id } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [scsrDetails, setscsrDetails] = useState();
  const [repairHisotry, setrepairHisotry] = useState([]);

  const [availableMines] = useState([]);

  const [inspections, setinspections] = useState();

  const [scsrAuditTrai, setscsrAuditTrai] = useState([]);

  const loadInformation = () => {
    setisLoading(true);
    httpClient
      .get(`/api/v1/scsr/${id}`)
      .then((response) => {
        const { data } = response;
        setscsrDetails({ ...data });
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const LoadRepairInformation = (from, to)=>

  {
    setisLoading(true);
   
    if(from && to)
    httpClient.get(`/api/v1/RFQ/SCSR/${id}?from=${from}&to=${to}`).then(response=>{
      const {data} = response;
      setrepairHisotry([...data])
      setisLoading(false);
    }).catch(err=>{
      setisLoading(false);
    })
    else
    httpClient.get(`/api/v1/RFQ/SCSR/${id}`).then(response=>{
      const {data} = response;
      setrepairHisotry([...data])
      setisLoading(false);
    }).catch(err=>{
      setisLoading(false);
    })
  }
  const loadInspectionHistory = (e) => {
    setisLoading(true);
    httpClient
      .get(`/api/v1/scsr/${id}/inspectionrecords?from=${e[0]}&to=${e[1]}`, {
        dateRange: e,
      })
      .then((response) => {
        const { data } = response;
        setinspections([...data]);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const loadscsrAuditTrail = (e, mode) => {
    setisLoading(true);
   
    let daterange = e;
    
    // if(daterange[0]?.format)
    // {
      
    //   daterange[0]=daterange[0]?.format("YYYY-MM-DD");
    //   daterange[1]=daterange[1]?.format("YYYY-MM-DD");
    // }
   
   
    httpClient
      .get(
        `/api/v1/scsr/${id}/audittrail?from=${daterange[0]}&to=${daterange[1]}&mode=${mode}`       
      )
      .then((response) => {
        const { data } = response;
        setscsrAuditTrai([...data]);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    loadInformation();
    return () => {};
  }, []);

  return (
    <>
      <Header />
      <PageHeader
        title={`SCSR Information`}
        subtitle={
          scsrDetails?.model &&
          `${scsrDetails?.model} - ${scsrDetails?.serialNumber}`
        }
      />

      <Container>
        <Spin spinning={isLoading} size="large">
        <Tabs
          defaultActiveKey="1"
          onChange={(e) => {
            if (e === "4") loadscsrAuditTrail(id, "latest");
            if(e==="2")loadInspectionHistory(e)
            if(e==="3")LoadRepairInformation(null,null)
            

          }}
        >
          <Tabs.TabPane tab="SCSR Information" key="1">
            {/* <ActivateSCSR reload={loadInformation} buttonTitle='Reactivate' {...scsrDetails} /> */}
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 15 }}
              layout="horizontal"
            >
              <Form.Item label="Status">
                <Input
                  readOnly
                  value={scsrDetails?.status?.toUpperCase()}
                  onChange={(e) =>
                    updateInputv2(e.target.value, "status", setscsrDetails)
                  }
                  style={{
                    color:
                      scsrDetails?.status.toUpperCase() === "ACTIVE"
                        ? "green"
                        : "red",
                  }}
                />
              </Form.Item>
              <Form.Item label="Mine">
                <Input
                  readOnly
                  value={scsrDetails?.mine?.name}
                  onChange={(e) =>
                    updateInputv2(
                      e.target.value,
                      "serialNumber",
                      setscsrDetails
                    )
                  }
                />
              </Form.Item>

              {/* <Form.Item label="Status">
            <Input
             readOnly
              style={{color:scsrDetails?.status?.toUpperCase()==="ACTIVE" ? "green" : "red"}}
              value={scsrDetails?.status?.toUpperCase()}
              // onChange={(e) =>
              //   updateInputv2(e.target.value, "serialNumber", setscsrDetails)
              // }
            />
          </Form.Item> */}

              <Form.Item label="Serial Number">
                <Input
                  readOnly
                  value={scsrDetails?.serialNumber}
                  onChange={(e) =>
                    updateInputv2(
                      e.target.value,
                      "serialNumber",
                      setscsrDetails
                    )
                  }
                />
              </Form.Item>
              <Form.Item label="Mine Number">
                <Input
                  readOnly
                  value={scsrDetails?.mineNumber}
                  onChange={(e) =>
                    updateInputv2(e.target.value, "mineNumber", setscsrDetails)
                  }
                />
              </Form.Item>
              <Form.Item label="Model">
                <Select
                  disabled
                  value={scsrDetails?.model}
                  onChange={(e) => updateInputv2(e, "model", setscsrDetails)}
                >
                  <Select.Option value="ROXY 40">ROXY 40</Select.Option>
                  <Select.Option value="AFROX PACK 35i">AFROX PACK 35i</Select.Option>
                 
                </Select>
              </Form.Item>

              <Form.Item label="Warranty Expiration">
                <DatePicker
                  format={"MM/YYYY"}
                  disabled
                  value={
                    scsrDetails?.warrantyExpiration
                      ? moment(scsrDetails?.warrantyExpiration)
                      : null
                  }
                  onChange={(e) =>
                    updateInputv2(e, "warrantyExpiration", setscsrDetails)
                  }
                />
              </Form.Item>
              <Form.Item label="Manufacturing Date">
                <DatePicker
                  disabled={true}
                  format={"MM/YYYY"}
                  value={
                    scsrDetails?.manufacturingDate
                      ? moment(scsrDetails?.manufacturingDate)
                      : null
                  }
                  onChange={(e) =>
                    updateInputv2(e, "manufacturingDate", setscsrDetails)
                  }
                />
              </Form.Item>

              {/* <Form.Item label="Mine">
            <Select value={scsrDetails?.mine} onChange={(e) => updateInputv2(e, "mine", setscsrDetails)}>
              {availableMines?.map(availableMine => <Select.Option key={availableMine?._id} value={availableMine?._id}>{availableMine?.name}</Select.Option>)}

            </Select>
          </Form.Item> */}
              {/* <div style={{ textAlign: "end" }}>
                <Button>Save</Button>
              </div> */}
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Inspections" key="2">
            <SearchBarWithTimeRange search={(e) => loadInspectionHistory(e)} />
              
            <DataTable
              pagination
              columns={[
                {
                  name: "Inspected Date",
                  selector: (row) => row?.inspectionDate,
                  sortable: true,
                  cell: (row) => (
                    <p>
                      {moment(row?.inspectionDate).format("YYYY-MM-DD HH:mm")}
                    </p>
                  ),
                  grow: 2,
                },
                {
                  name: "Inspected By",
                  selector: (row) => row?.data?.inspection?.user,
                  sortable: true,
                  cell: (row) => (
                    <p>
                      {row?.data?.inspection?.user?.name}{" "}
                      {row?.data?.inspection?.user?.surname}
                    </p>
                  ),
                  grow: 2,
                },
                {
                  name: "Serial Number",
                  selector: (row) => row?.data?.scsr?.serialNumber,
                  sortable: true,
                  grow: 2,
                  cell: (row) => <p>{row?.data?.scsr?.serialNumber}</p>,
                },
                {
                  sortable: true,
                  name: "Result",
                  selector: (row) => row?.data?.status,
                  cell: (row) => <p>{row?.data?.status?.toUpperCase()}</p>,
                  grow: 2,
                },
                {
                  sortable: true,
                  name: "Code",
                  selector: (row) => row?.data?.code?.code,
                  cell: (row) =>
                    row?.data?.code?.map((code) => (
                      <p>
                        ({code?.code}) {code?.text}
                      </p>
                    )),
                  grow: 2,
                },
                {
                  name: "Comments",
                  selector: (row) => row?.data?.comment,
                  cell: (row) => <p>{row?.data?.comment}</p>,
                  grow: 2,
                },
                {
                  name: "Inspection",
                  selector: (row) => row?.inspection,
                  cell: (row) => (
                    <Link to={`/app/inspections/${row?.metadata.inspection}`}>
                      Inspection
                    </Link>
                  ),
                  grow: 2,
                },
                {
                  name: "Pictures",
                  cell: (row) => (
                    <p>
                      {/* <Image
                                    preview={{ visible: false }}
                                    width={50}
                                    style={{ margin: "10px" }}
                                    src={`/api/images/${row?.pictures[0].name}`}
                                    onClick={() => setVisible(true)}
                                /> */}
                      <Image.PreviewGroup>
                        {row?.data?.pictures?.map((picture) => (
                          <Image
                            width={"30px"}
                            src={`/api/images/${picture?.name}`}
                          />
                        ))}
                      </Image.PreviewGroup>
                    </p>
                  ),
                  grow: 2,
                },
              ]}
              data={inspections}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Repairs" key="3">
          
          <SearchBarWithTimeRange search={(e) => LoadRepairInformation(e[0],e[1])} />
          <DataTable
              pagination
              columns={[
                {
                  name: "Repair Date",
                  selector: (row) => row?.creationDate,
                  sortable: true,
                  cell: (row) => (
                    <p>
                      {moment(row?.creationDate).format("YYYY-MM-DD HH:mm")}
                    </p>
                  ),
                  grow: 2,
                },
                {
                  name: "Repair  By",
                  selector: (row) => row?.repairedBy?._id,
                  sortable: true,
                  cell: (row) => (
                    <p>
                      {row?.repairedBy?.name}{" "}
                      {row?.repairedBy?.surname}
                    </p>
                  ),
                  grow: 2,
                },
                {
                  name: "Items Repaired",
                  selector: (row) => row?.itemsRepaired?.length,
                  sortable: true,
                  cell: (row) => (  <ul>
                   { row?.itemsRepaired?.map(issue=><><li>{issue?.description} - {issue?.details} - R{issue?.cost}</li> </>)}
                  
                    </ul>
                  ),
                  grow: 2,
                },
                {
                  name: "Issue Discovered",
                  selector: (row) => row?.issueDiscovered?.length,
                  sortable: true,
                  cell: (row) => (  <ul>
                   { row?.issueDiscovered?.map(issue=><><li>{issue}</li></>)}
                  
                    </ul>
                  ),
                  grow: 2,
                }
              ]}
              data={repairHisotry}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Audit Trail" key="4">
            <DisplayBasedOnRole roles={["Admin"]}>
              <SearchBarWithTimeRange search={(e) => loadscsrAuditTrail(e,null)} />
              <>
                <Timeline mode="left">
                  {scsrAuditTrai?.map((autitrail) => (
                    <Timeline.Item
                      color={autitrail?.changes?.status==="active" ? "green":"red"}
                      label={moment(autitrail?.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    >
                      <p>
                        <Descriptions
                          column={1}
                          layout="horizontal"
                          title="Details"
                        >
                          <Descriptions.Item label="Modify By">
                          {autitrail?.modifiedBy
                            ? `${autitrail?.modifiedBy?.name} ${autitrail?.modifiedBy?.surname}`
                            : "-"}
                          </Descriptions.Item>
                         
                          <Descriptions.Item label="Status" >
                           
                           <div style={{fontWeight:"bold",color:autitrail?.newData?.status==="active" ? "green":"red"}}> {autitrail?.newData?.status?.toUpperCase()}</div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Comments">
                            <p> {autitrail?.newData?.comment}</p>
                          </Descriptions.Item>
                        </Descriptions>
                      </p>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </>

            
              
            </DisplayBasedOnRole>
          </Tabs.TabPane>
        </Tabs>
        </Spin>
        {/* <Spinner isLoading={isLoading} /> */}

       
      </Container>
    </>
  );
}

export default function SCSR() {
  const [scsrs, setscsrs] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);

  const loadAll = () => {
    setisLoading(true);
    httpClient
      .get("/api/v1/scsr")
      .then((response) => {
        const { data } = response;

        setscsrs([...data]);

        setisLoading(false);
      })
      .catch((errr) => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    loadAll();

    return () => {};
  }, []);

  return (
    <>
      <Header />
      <PageHeader title={"SCSRs"} />
      <Container>
        <SearchBar
          placeholder="Please enter a serial number"
          search={(value) => {
            // if (value.length > 3)
            setisLoading(true);
              httpClient
                .get(`/api/v1/scsr/search?serialNumber=${value}`)
                .then((response) => {
                  const { data } = response;
                  setscsrs([...data]);
                  setisLoading(false);
                })
                .catch((err) => {setisLoading(false);});

            if (value.length === 0) loadAll();
          }}
        />

        {/* <UpdateCreateUserV2 reload={loadUsers} title="New User" />
      <ImportUser reload={loadUsers} title="Import  Users" /> */}
        {/* <ImportMines title="Import Mines" /> */}

      <Spin size="large" spinning={isLoading}>
      <DisplayBasedOnRole roles={["Admin"]}>
      <CreateUpdeSCSR reload={loadAll} title="New  SCSR's" />
          <ImportSCSR reload={loadAll} title="Import  SCSR's" />
          
        </DisplayBasedOnRole>

        {/* <Spinner isLoading={isLoading} /> */}
        <DataTable
          selectableRows
          onSelectedRowsChange={(rows) => {
            //   alert(
            //   JSON.stringify(
            //     rows?.selectedRows?.map((rowSelected) => rowSelected?._id)
            //   )
            // )
          }}
          pagination
          columns={[
            {
              name: "SN",
              selector: (row) => row.serialNumber,
              sortable: true,
              cell: (row) => <>{row.serialNumber}</>,
              grow: 1,
            },
            {
              name: "Status",
              selector: (row) => row.status,
              cell: (row) => (
                <>
                  <Badge bg={row.status === "active" ? "success" : "danger"}>
                    {row.status?.toUpperCase()}
                  </Badge>
                </>
              ),

              sortable: true,
              grow: 2,
            },
            {
              name: "Mine Number",
              selector: (row) => row?.mineNumber,
              sortable: true,
              grow: 2,
            },
            {
              name: "Model",
              selector: (row) => row.model,
            },
            {
              name: "Manufacturing Date",
              selector: (row) => row.manufacturingDate,
              cell: (row) => moment(row.manufacturingDate).format("MM/YYYY"),

              sortable: true,
              grow: 2,
            },
            {
              compact: true,
              name: "Mine",
              selector: (row) => row?.mine?._id,
              cell: (row) => (row?.mine?.name ? row?.mine?.name : "-"),

              grow: 2,
            },
            {
              name: "Warranty Expiration Date",
              selector: (row) => row.warrantyExpiration,
              cell: (row) => (
                <div style={{ textAlign: "center" }}>
                  {row?.warrantyExpiration
                    ? moment(row.warrantyExpiration).format("MM/YYYY")
                    : "-"}
                </div>
              ),
              sortable: true,
              grow: 2,
            },
            {
              name: "Creation Date",
              selector: (row) => row.creationDate,
              cell: (row) =>
                moment(row.creationDate).format("YYYY-MM-DD HH:mm"),
              sortable: true,
              grow: 2,
            },
            {
              cell: (row) => (
                <>
                  <DisplayBasedOnRole roles={["Admin", "Mine"]}>
                    <ChangeSCSRStatus {...row} reload={loadAll} />
                  </DisplayBasedOnRole>
                  <DisplayBasedOnRole roles={["Admin"]}>
                    <ActivateSCSR {...row} />
                    <CreateUpdeSCSR
                      _id={row._id}
                      color="warning"
                      reload={loadAll}
                      title={<EditFilled />}
                    />
                  </DisplayBasedOnRole>

                  <Button
                    style={{ margin: "5px" }}
                    size="sm"
                    onClick={(e) => {
                      navigate(`/app/scsrs/${row._id}`);
                    }}
                  >
                    <InfoCircleFilled />
                  </Button>
                </>
              ),
            },
          ]}
          data={scsrs}
        />
      </Spin>
   
      </Container>
    </>
  );
}

const ActivateSCSR = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [scsrCurrentinformation, setscsrCurrentinformation] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
    handleLoadSCSRInformation();
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setactivationComment({});
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setactivationComment({});
  };

  const handleLoadSCSRInformation = () => {
    httpClient
      .get(`/api/v1/scsr/${props._id}/fulldetails`)
      .then((response) => {
        const { data } = response;
        setscsrCurrentinformation({ ...data });
      })
      .catch((err) => {});
  };

  const [activationComment, setactivationComment] = useState({});

  const handleActivateSCSR = () => {
    httpClient
      .put(`/api/v1/scsr/${props?._id}/reavail`, {
        comment: activationComment.comment,
      })
      .then((res) => {
        message.success(`${props.serialNumber} successfully activated !`);
        handleOk();

        if (props.reload) props.reload();
      })
      .catch((err) => {
        message.error(
          `Failed to activate ${props.serialNumber} (${
            err?.response?.data || err?.message
          })`
        );
      });
  };

  return (
    props?.status !== "active" && (
      <>
        <Button
          style={{ margin: "5px" }}
          size="sm"
          variant="success"
          type="primary"
          onClick={showModal}
        >
          <Infinity /> {props.buttonTitle}
        </Button>
        <Modal
          width={800}
          footer={[
            <Button onClick={handleActivateSCSR} size="sm">
              Active
            </Button>,
          ]}
          title={`Activate ${props?.serialNumber}`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Descriptions title="SCSR Info">
            <Descriptions.Item label="Serial Number">
              {scsrCurrentinformation?.serialNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Mine Number">
              {scsrCurrentinformation?.mineNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Last Modified By">
              {scsrCurrentinformation.lastModifiedBy
                ? `${scsrCurrentinformation.lastModifiedBy?.name} ${scsrCurrentinformation.lastModifiedBy?.surname}`
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Current Status">
              {scsrCurrentinformation?.status?.toUpperCase()}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={1} title="Current Repair Order Information">
            <Descriptions.Item label="Status">
              {scsrCurrentinformation?.underRepair?.status?.toUpperCase()}
            </Descriptions.Item>
            <Descriptions.Item label="Creation Date">
              {moment(scsrCurrentinformation?.underRepair?.creationDate).format(
                "YYYY-MM-DD HH:mm"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RFQS">
          
              {scsrCurrentinformation?.underRepair?.rfqs?.map(
                (rfq) =>  <a href={`/app/repairs/${scsrCurrentinformation?.underRepair?._id}`}>{rfq}</a> 
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Related SCSRS" style={{ width: "300px" }}>
              {scsrCurrentinformation?.underRepair?.scsrs?.map((rfq) => (
                <div style={{ fontWeight: "bold", marginLeft: "5px" }}>
                 
                  {rfq?.serialNumber}
                </div>
              ))}
            </Descriptions.Item>
          </Descriptions>
          <Input.TextArea
            value={activationComment.comment}
            onChange={(e) =>
              updateInputv2(
                e.currentTarget.value,
                "comment",
                setactivationComment
              )
            }
            placeholder={`Please enter the reason you want to reactivate ${props?.serialNumber}`}
          ></Input.TextArea>
        </Modal>
      </>
    )
  );
};

const ChangeSCSRStatus = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [scsrCurrentinformation, setscsrCurrentinformation] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
    handleLoadSCSRInformation();
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setactivationComment({});
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setactivationComment({});
  };

  const handleLoadSCSRInformation = () => {
    httpClient
      .get(`/api/v1/scsr/${props._id}/fulldetails`)
      .then((response) => {
        const { data } = response;
        setscsrCurrentinformation({ ...data });
      })
      .catch((err) => {});
  };

  const [activationComment, setactivationComment] = useState({});

  const handleActivateSCSR = () => {
    confirm({
      title: "Do you really want to quaranined these SCSR?",
      icon: <ExclamationCircleFilled />,
      content: <></>,
      onOk() {
        httpClient
          .put(`/api/v1/scsr/${props?._id}/quarantined`, {
            comment: activationComment.comment,
          })
          .then((res) => {
            message.success(`${props.serialNumber} successfully Quarantined !`);
            handleOk();

            if (props.reload) props.reload();
          })
          .catch((err) => {
            message.error(
              `Failed to activate ${props.serialNumber} (${
                err?.response?.data || err?.message
              })`
            );
          });
      },
      onCancel() {},
    });
  };

  return (
    props?.status === "active" && (
      <>
        <Button
          style={{ margin: "5px" }}
          size="sm"
          variant="danger"
          type="primary"
          onClick={showModal}
        >
          <BoxArrowInDown /> {props.buttonTitle}
        </Button>
        <Modal
          width={800}
          footer={[
            <Button onClick={handleActivateSCSR} size="sm">
              Quarantine
            </Button>,
          ]}
          title={`Quarantine ${props?.serialNumber}`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Descriptions title="SCSR Info">
            <Descriptions.Item label="Serial Number">
              {scsrCurrentinformation?.serialNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Mine Number">
              {scsrCurrentinformation?.mineNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Last Modified By">
              {scsrCurrentinformation.lastModifiedBy
                ? `${scsrCurrentinformation.lastModifiedBy?.name} ${scsrCurrentinformation.lastModifiedBy?.surname}`
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Current Status">
              {scsrCurrentinformation?.status?.toUpperCase()}
            </Descriptions.Item>
          </Descriptions>
          {/* <Descriptions column={1} title="Current Repair Order Information">
        <Descriptions.Item label="Status">{scsrCurrentinformation?.underRepair?.status?.toUpperCase()}</Descriptions.Item>
          <Descriptions.Item label="Creation Date">{moment(scsrCurrentinformation?.underRepair?.creationDate).format("YYYY-MM-DD HH:mm")}</Descriptions.Item>
          <Descriptions.Item label="RFQS">{scsrCurrentinformation?.underRepair?.rfqs?.map(rfq => `${rfq} `)}</Descriptions.Item>
          <Descriptions.Item label="Related SCSRS"  style={{width:"300px"}}>
            {scsrCurrentinformation?.underRepair?.scsrs?.map(rfq => <div style={{ fontWeight: "bold", marginLeft: "5px" }}>{rfq?.serialNumber}</div>)}
          </Descriptions.Item>

        </Descriptions> */}
          <Input.TextArea
            value={activationComment.comment}
            onChange={(e) =>
              updateInputv2(
                e.currentTarget.value,
                "comment",
                setactivationComment
              )
            }
            placeholder={`Please enter the reason you want to quarantine ${props?.serialNumber}`}
          ></Input.TextArea>
        </Modal>
      </>
    )
  );
};
