
import { EditFilled } from "@ant-design/icons";
import { Form, Input, message, Modal, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import SearchBar from "../components/SearchBar";
import Spinner from "../components/Spinner";
import { httpClient } from "../services/http.service";
import { updateInputv2 } from "../utilities";

const ImportMines = (props) => {
  var file;

  const [isModalOpen, setisModalOpen] = useState(false);

  
  const [isLoading, setisLoading] = useState(false);

  const handleOpenModal = () => {
    setisModalOpen(true);
  };

  const [resultDetails, setresultDetails] = useState([]);

  

  const handleCancel = () => {
    setisModalOpen(false);
    setresultDetails([]);
  };

  function ImportBulkUsers(file) {
    let formData = new FormData();
    formData.append("mines", file, file.name);
    setisLoading(true);
    httpClient
      .post("/api/v1/mines/bulk", formData)
      .then((response) => {
      
        setisLoading(false);

        message.success(`Mines imported successfully !`);
        if(props.reload)
        props.reload();

        
      })
      .catch((error) => {
        message.error(`Failed to upload mines !`);
        setresultDetails(error?.response?.data || []);
        //props.reload();
        setisLoading(false);
      });
  }

  return (
    <>
      <Button
        onClick={handleOpenModal}
        size="sm"
        style={{ margin: 2 }}
        variant={props.color || "primary"}
      >
        {props.title || <> </>}
      </Button>
      <Modal
        width={600}
        footer={[
          <Button disabled={isLoading} onClick={() => ImportBulkUsers(file)}>Import Mines</Button>,
        ]}
        title={props.title}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Input
            accept=".csv"
            type="file"
            onChange={(e) => {
              file = e.target.files[0];
              console.log(`File`, file);
            }}
          />

          {resultDetails?.map((resul) => (
            <p
              key={resul?.name}
              style={{ color: resul?.failed ? "red" : "green" }}
            >
              {resul?.name}, {resul?.message}{" "}
            </p>
          ))}
        </Form>
      </Modal>
    </>
  );
};

const CreateUpdateMine = (props) => {

  const [isModalOpen, setisModalOpen] = useState(false);

  const [isBusy, setisBusy] = useState(false);

  const handleOpenModal = () => {
    setisModalOpen(true);
    loadMineInformation();
  };


  const [mineDetails, setmineDetails] = useState({});

  const loadMineInformation = ()=>{
    if(!props._id) return ;

    setisBusy(true);
    httpClient.get(`/api/v1/mines/${props._id}`).then(response=>{
      const {data} = response;
      setmineDetails({...data});
      setisBusy(false);
    }).catch(err=>{
      setisBusy(false);
    });
  }
  

  const handleCancel = () => {
    setisModalOpen(false);
    setmineDetails({});

    if(props.reload)
    props.reload();
  };

  const handleCreateMine =()=>{
    if(props._id) return;

    httpClient.post('/api/v1/mines/',{
      name:mineDetails.name,
      mineCode:mineDetails.mineCode,
      address:mineDetails.address,
      description:mineDetails.description,
      contactEmails:mineDetails?.contactEmails
    }).then(response=>{
     // const {data} = response;
      message.success("Mine successfully created !");
      handleCancel();
    }).catch(error=>{

    })
  }

  const handleUpdateMine =()=>{
    if(!props._id) return;
    
    httpClient.put(`/api/v1/mines/${props._id}`,{
      name:mineDetails.name,
      mineCode:mineDetails.mineCode,
      address:mineDetails.address,
      description:mineDetails.description,
      contactEmails:mineDetails?.contactEmails
    }).then(response=>{
     // const {data} = response;
      message.success("Mine successfully updated !");
      handleCancel();
    }).catch(error=>{

    })
  }


  return (
    <>
      <Button
        onClick={handleOpenModal}
        size="sm"
        style={{ margin: 2 }}
        variant={props.color || "primary"}
      >
        {props.title || <> </>}
      </Button>
      <Modal
        width={600}
        footer={[
          <Button size="sm" onClick={() =>{handleCreateMine();handleUpdateMine();}}>Save</Button>,
        ]}
        title={props.title}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form
          disabled={isBusy}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 17 }}
          layout="horizontal"
        >
        <Form.Item label="Name">
            <Input
              value={mineDetails?.name}
              onChange={(e) =>
                updateInputv2(e.target.value, "name", setmineDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Mine Code">
            <Input
              value={mineDetails?.mineCode}
              onChange={(e) =>
                updateInputv2(e.target.value, "mineCode", setmineDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Address">
            <Input.TextArea
            rows={4}
              value={mineDetails?.address}
              onChange={(e) =>
                updateInputv2(e.target.value, "address", setmineDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Extra Details">
            <Input.TextArea
            rows={4}
              value={mineDetails?.description}
              onChange={(e) =>
                updateInputv2(e.target.value, "description", setmineDetails)
              }
            />
          </Form.Item>
          <Form.Item label="Emails">
            <Select  mode="tags" value={mineDetails?.contactEmails}  onChange={(e) =>
                updateInputv2(e, "contactEmails", setmineDetails)
              } />
           
          </Form.Item>
          {/* <Form.Item label="Contact Emails">
            <Input.TextArea
            rows={4}
              value={mineDetails?.description}
              onChange={(e) =>
                updateInputv2(e.target.value, "description", setmineDetails)
              }
            />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};

// const DeleteMine = (props)=>{

//     return (<>
//     <Button onClick={e=>{
//        Modal.argumentsconfirm({
//         title: 'Do you Want to delete these mine ?',
//         icon: <ExclamationCircleOutlined />,
        
//         onOk() {
//           console.log('OK');
//           httpClient.delete(``)
//         },
//         onCancel() {
//           console.log('Cancel');
//         },
//       });
//     }}>Delete</Button>
//     </>)
// }

export default function Mines() {
  const [mines, setmines] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const loadMines = () => {
    setIsLoading(true);
    httpClient
      .get("/api/v1/mines")
      .then((response) => {
        const { data } = response;
        setmines([...data]);
        setIsLoading(false);
      })
      .catch((error) => {setIsLoading(false);});
  };

  useEffect(() => {
    loadMines();

    return () => {};
  }, []);

  return (
    <>
      <Header />
      <PageHeader title={"Mines"} />
      <Container>
        <SearchBar
        placeholder="Please enter a mine name or or mine code."
          search={(value) => {
            if (value.length > 3)
              httpClient
                .get(`/api/v1/mines/search?search=${value}`)
                .then((response) => {
                  const { data } = response;
                  setmines([...data]);
                })
                .catch((err) => {});

            if (value.length === 0) loadMines();
          }}
        />

        {/* <UpdateCreateUserV2 reload={loadUsers} title="New User" />
        <ImportUser reload={loadUsers} title="Import  Users" /> */}
        <ImportMines reload={loadMines} title="Import Mines" />
        <CreateUpdateMine reload={loadMines} title="New Mine"  />
        <Spinner isLoading={isLoading} />
        <DataTable
          pagination
          columns={[
            {
              name: "Name",
              selector: (row) => row.name,
              sortable: true,
              cell: (row) => <p>{row?.name}</p>,
              grow: 2,
            },
            {
              name: "Mine Code",
              selector: (row) => row?.mineCode,
              sortable: true,
            },
            {
              name: "Address",
              selector: (row) => row.address,
              cell: (row) => <p>{row.address}</p>,
              grow:2
            },
            {
              name: "Extra Details",
              selector: (row) => row.description,
              cell: (row) => <p>{row.description}</p>,
              grow:2
            },
            {
              name: "Creation Date",
              selector: (row) => row?.creationDate,
              cell: (row) =>
                moment(row?.creationDate).format("YYYY-MM-DD HH:mm"),
              sortable: true,
              grow: 2,
            },
            {
              cell:(row)=> <CreateUpdateMine _id={row._id} color="warning" reload={loadMines} title={<EditFilled />} />
            }
          ]}
          data={mines}
        />
      </Container>
    </>
  );
}
