import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import {httpClient} from "../services/http.service";

export function DownloadFile({ fileUrl, onError, buttonTitle, disabled }) {

  const [isLoading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const response = await httpClient.axiosInstace({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = response.headers['x-file-name'];
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button style={{margin:"5px"}} disabled={isLoading || disabled} size='sm' onClick={handleDownload}> <Download/>{isLoading ? 'Downloading...' : buttonTitle}</Button>
    </>
  );
}
