import React, { useState } from 'react';
import { getObject } from '../services/storage.service';

// import { useNavigate } from 'react-router-dom';
export function DisplayBasedOnRole(props) {
  const [userInformation] = useState({ ...getObject("jwt_token") });

  return (
    props.roles.find(role => role === userInformation?.user.role) && <>
      {props.children}
    </>
  );
}
