import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { ChangePassword } from '../services/auth.service'
import { defaultStyling, updateInputv2 } from '../utilities'
import {  useParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { httpClient } from '../services/http.service';

// backgroundColor: ""

export default function PaswordReset() {
    const [userLoginDetails, setuserLoginDetails] = useState({});
    const [isBusy, setisBusy] = useState(true);
    
    let { code } = useParams();

    const loadResetPasswordInfo =()=>{
        setisBusy(true);
        httpClient.get(`/api/v1/auth/passwordreset/${code}`).then(response=>{
         const {data} = response;
         setuserLoginDetails({email:data?.email, name:data?.name, message:`Hi ${data?.name} ${data?.surname} !`});
         setisBusy(false);
        }).catch(err=>{
            
        })
    }

    useEffect(() => {
      
        loadResetPasswordInfo();
      return () => {
        
      }
    }, [])
    

    return (
        <>
            <div style={{ marginTop: 0 }}>
                <div style={{ height: "100vh", textAlign: "center" }}>
                    <img style={{ height: "230px", width: "230px" }} alt='iTeloma' src='/logo2.png'></img>
                    <form onSubmit={e => {
                        e.preventDefault();
                        setisBusy(true);
                        
                        ChangePassword(userLoginDetails?.email, userLoginDetails?.password, userLoginDetails?.password_confirmation, code,()=> setisBusy(false))
                    }} style={{ padding: "50px", backgroundColor: "#415e9a", maxWidth: "500px", position: "absolute", left: "20px", right: "20px", marginRight: "auto", marginLeft: "auto", display: "grid", flexDirection: "column" }}>
                        <h1 style={{ color: "white", fontSize: "2rem" }}>{userLoginDetails?.message}</h1>
                        <h1 style={{ color: "white", fontSize: "1.5rem" }}>Please enter your new password.</h1>
                       
                        <Input onChange={e => updateInputv2(e.target.value, "password", setuserLoginDetails)} type="password" style={defaultStyling.input} placeholder='Password' />
                        <Input onChange={e => updateInputv2(e.target.value, "password_confirmation", setuserLoginDetails)} type="password" style={defaultStyling.input} placeholder='Confirm Password' />
                        <Button disabled={isBusy} type='submit' style={defaultStyling.button} >CHANGE PASSWORD</Button>
                        
                    </form>
                </div>

            </div>
        </>
    )
}
