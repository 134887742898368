
// import { useEffect, useState } from "react";
// // import { Redirect, Route } from "react-router";
// import { getObject } from "../services/storage.service";



export const updateInput = (e,name,setValue)=>{setValue(old=>({...old,[name]:e.target.value}))};

export const updateInputv2 = (e,name,setValue,toUpper=false)=>{
    
    let input = toUpper ? e.toString().toUpperCase():e;
    setValue(old=>({...old,[name]: input}));
};

// export const defaultStyling={
//     input:{ marginTop: "20px", height: "50px", fontSize: "1.2rem" },
//     button: {marginTop: "20px", height: "50px", fontSize: "1.5rem", color: "white", border: "none", cursor: "pointer" }
// }

export const defaultStyling={
    input:{ marginTop: "20px", },
    button: {marginTop: "20px", color: "white", border: "none", cursor: "pointer" }
}




// export const ProtectRoute =(props)=>{

//     const [user, setuser] = useState({})

//    useEffect(() => {
     
//     async function GetUser ()
//     {
//        let userInfo = await getObject("jwt_token");

//        setuser({...userInfo});

     
//     }

//     GetUser().catch(err=>{})
   
//      return () => {
       
//      }
//    }, [])
   

//     return (  user ? <Route path={props.path} component={props.component}/> : <Redirect to="/login" />
   
//  )
// }



// export const showHelloToast = async (msg, type) => {
//   await Toast.show({
//     text: 'Hello!'
//   });
// };