import { Input } from 'antd'
import React, { useState } from 'react'
import { ResetUserPassword } from '../services/auth.service'
import { defaultStyling, updateInputv2 } from '../utilities'
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';

// backgroundColor: ""

export default function PasswordResetRequest() {
    const [userLoginDetails, setuserLoginDetails] = useState({});
    const [isBusy, setisBusy] = useState(false);

    const navigate = useNavigate();
    return (
        <>
            <div style={{ marginTop: 0 }}>
                <div style={{ height: "100vh", textAlign: "center" }}>
                    <img style={{ height: "230px", width: "230px" }} alt='iTeloma' src='/logo2.png'></img>
                    <form onSubmit={e => {
                        e.preventDefault();
                        setisBusy(true);

                        ResetUserPassword(userLoginDetails?.email,() => {
                            setisBusy(true);
                            setTimeout(() => {
                                navigate("/app/login", { replace: true })
                            }, 1500);
                        });
                    }} style={{ padding: "50px", backgroundColor: "#415e9a", maxWidth: "500px", position: "absolute", left: "20px", right: "20px", marginRight: "auto", marginLeft: "auto", display: "grid", flexDirection: "column" }}>
                        <h1 style={{ color: "white", fontSize: "2rem" }}>Password Reset</h1>
                        <h1 style={{ color: "white", fontSize: "1.5rem" }}>Please enter your email</h1>
                        <Input onChange={e => updateInputv2(e.target.value, "email", setuserLoginDetails)} style={defaultStyling.input} placeholder='Email' />

                        <Button disabled={isBusy} type='submit' style={defaultStyling.button}  >RESET PASSWORD</Button>
                    </form>
                </div>

            </div>
        </>
    )
}
