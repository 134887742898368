import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../components/PageHeader";
import { Button, Container } from "react-bootstrap";
import { SearchBarWithTimeRangeSelect } from "../components/SearchBar";
import Header from "../components/Header";
import { httpClient } from "../services/http.service";
import { Descriptions, Spin } from "antd";
import { useReactToPrint } from 'react-to-print';
import DataTable from "react-data-table-component";
import moment from "moment";

export default function Reports() {
  const reportsName = [
    { label: "Inspection Faults Summary", value: "test2" },
    { label: "Inspections Statistics", value: "test3" },
    { label: "Repairs Statistics per Mine & Device", value: "test4" },
    { label: "Repaired Items Statistics", value: "test5" },
    { label: "Issue Discovered Statistics", value: "test6" },
    { label: "SCSRs Repair Full Details", value: "test10" },
    // {label:"SCSRs Repair Full Details",value:"test11"}
  ];

  const [isLoading, setisLoading] = useState(false);
  const [reportDetails, setreportDetails] = useState();
  const [mines, setmines] = useState()

  const reportGeneratorHandler = (date, reportName, mineIds) => {

    
     let tempDate = date;
     tempDate[0] = tempDate[0]?.startOf('day');
     tempDate[1] = tempDate[1]?.endOf('day');

     console.log(`Date passed`,tempDate);
                      
    setisLoading(true);
    httpClient
      .post(
        `/api/v1/reports?reportName=${reportName}&from=${tempDate[0]}&to=${tempDate[1]}`, { mineIds: mineIds }
      )
      .then((response) => {
        const { data } = response;
        setreportDetails(data);
        setisLoading(false);
      })
      .catch((err) => {
        alert(`Error`);
        setisLoading(false);
      });
  };
  const loadMines = () => {
    setisLoading(true);
    httpClient.get("/api/v1/mines")
      .then(response => {
        setisLoading(false);
        setmines(response?.data?.map(mine => ({ value: mine?._id, label: mine?.name })))
      }).catch(err => { setisLoading(false); })
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    loadMines();
    return () => {
    }
  }, [])

  const componentRef = useRef();
  return (
    <>

      <Header />
      <PageHeader title={"Reports"} />
      <Spin spinning={isLoading}>
        <Container ref={componentRef}>
          <SearchBarWithTimeRangeSelect
            buttonTitle={"Generate"}
            selectOptions={reportsName}
            selectPlaceHolder={"Select Report"}
            selectPlaceHolder1={"Select Mine"}
            search={reportGeneratorHandler}
            selectOptions1={mines}
            printHandler={handlePrint}
            printEnable={reportDetails?.report}
          />


          <MinesInspectionResumer {...reportDetails} />
          <MinesInspectionRatioToFailureReport {...reportDetails} />
          <RepairResumer {...reportDetails} />
          <RepairedItemCountGroup {...reportDetails} />
          <IssuesItemCountGroup {...reportDetails} />
          <SCSRInspectionFullDetails {...reportDetails} />

        </Container>
      </Spin>
    </>
  );
};

const MinesInspectionResumer = ({ reportName, report, from, to }) => {
  return (
    reportName === "test2" && (
      <>
        <Descriptions bordered >
          <Descriptions.Item label="From">{from}</Descriptions.Item>
          <Descriptions.Item label="To">{to}</Descriptions.Item>
        </Descriptions>
        {report?.map((reportDetails) => (
          <Descriptions bordered title={reportDetails?._id?.mineName}>
            {reportDetails?.codes?.map((codeDetails) => (
              <Descriptions.Item label={codeDetails?.text}>
                {codeDetails?.count}
              </Descriptions.Item>
            ))}
            <Descriptions.Item
              style={{ fontWeight: "bold" }}
              label="Total SCSR Isnpected"
            >
              {reportDetails?.total}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </>
    )
  );
};

const SCSRInspectionFullDetails = ({ reportName, report, from, to }) => {
  return (
    reportName === "test10" && (
      <>
        <Descriptions bordered title={``}>
          {/* <Descriptions bordered title={`Report : ${reportName}`}> */}
          <Descriptions.Item label="From">{from}</Descriptions.Item>
          <Descriptions.Item label="To">{to}</Descriptions.Item>
        </Descriptions>
        <DataTable columns={[
          { name: "Date", selector: row => row?.creationDate, sortable: true, cell: row => moment(row?.creationDate).format("YYYY-MM-DD HH:mm") },
          { name: "S/N", selector: row => row?.serialNumber, sortable: true },
          { name: "Mine/N", selector: row => row?.mineNumber, sortable: true },
          { name: "Manufacturing Date", selector: row => row?.manufacturingDate, sortable: true, cell: row => moment(row?.manufacturingDate).format("YYYY-MM") },
          { name: "Warranty Expirate Date", selector: row => row?.warrantyExpiration, sortable: true, cell: row => moment(row?.warrantyExpiration).format("YYYY-MM") },
          {
            name: "Issue Discovered", grow: 2, cell: row => <>
              <p>
                {row?.issueDiscovered?.map(issue => <li>{issue[0] || ""} - {issue[1] || ""}</li>)}
              </p>
            </>
          },
          { name: "Comments", selector: row => row?.comments, sortable: true, cell: row => <p>{row?.comments}</p> },
          {
            name: "Item Repaired", grow: 2, cell: row => <>

              <p>
                {row?.itemsRepaired?.map(item => <li>{item?.description} - {item?.details} - R{item?.cost}</li>)}
              </p>
            </>
          },
          {
            name: "Latest Inspection", grow: 2, selector: row => row?.latestInspections[0]?.data?.status, sortable: true, cell: row => <>
              <p>
                {row?.latestInspections?.map(inspection => <p>
                  <div>{inspection?.data?.status} - {moment(inspection?.data?.creationDate).format("YYYY-MM-DD HH:mm")}</div>
                  <div>{inspection?.data?.code[0]?.code} - {inspection?.data?.code[0]?.text}</div>
                </p>)}
              </p>
            </>
          },
          { name: "Repair Status", selector: row => row?.status, sortable: true, cell: row => row?.status?.toUpperCase() },
        ]} data={report}>

        </DataTable>
      </>
    )
  );
};

const RepairResumer = ({ reportName, report, from, to }) => {
  return (
    reportName === "test4" && (
      <>
        <Descriptions bordered >
          <Descriptions.Item label="From">{from}</Descriptions.Item>
          <Descriptions.Item label="To">{to}</Descriptions.Item>
        </Descriptions>
        {report?.map((reportDetails) => (
          <Descriptions bordered title={`${reportDetails?._id} - (R ${Number(reportDetails?.totalCost)})`}>
            {/* <Descriptions.Item label={"SCSRS"}>
                {reportDetails?.scsrs?.map((scsr)=> <li>{scsr}</li>)}
                </Descriptions.Item> */}
            {reportDetails?.scsrs?.map((scsr) => <Descriptions.Item label={`SN:${scsr?.serialNumber}`}>R {Number(scsr?.totalCost)}</Descriptions.Item>)}
            <Descriptions.Item
              style={{ fontWeight: "bold" }}
              label="Total Cost"
            >
              R {Number(reportDetails?.totalCost)}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </>
    )
  );
};

const RepairedItemCountGroup = ({ reportName, report, from, to }) => {
  return (
    reportName === "test5" && (
      <>
        <Descriptions column={2} bordered >
          <Descriptions.Item label="From">{from}</Descriptions.Item>
          <Descriptions.Item label="To">{to}</Descriptions.Item>
        </Descriptions>
        {report?.map((reportDetails) => (
          <Descriptions column={1} bordered title={`${reportDetails?._id?.[0]}`}>

            <Descriptions.Item>
              {reportDetails?.items?.map(item =>
                <Descriptions column={1} bordered title={`${item?.item?.description} - ${item?.item?.details || ""}`}>
                  <Descriptions.Item label="Count">{item?.count}</Descriptions.Item>
                  <Descriptions.Item label="Serial Numbers">{item?.scsrSerialNumbers?.map(scsr => `#${scsr?.[0]} `)}</Descriptions.Item>
                </Descriptions>)}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </>
    )
  );
};

const IssuesItemCountGroup = ({ reportName, report, from, to }) => {
  return (
    reportName === "test6" && (
      <>
        <Descriptions column={2} bordered >
          <Descriptions.Item label="From">{from}</Descriptions.Item>
          <Descriptions.Item label="To">{to}</Descriptions.Item>
        </Descriptions>
        {report?.map((reportDetails) => (
          <Descriptions column={1} bordered title={`${reportDetails?._id?.[0]}`}>

            <Descriptions.Item>
              {reportDetails?.items?.map(item =>
                <Descriptions column={1} bordered title={`${item?.item[0] || ""} - ${item?.item[1] || ""}`}>
                  <Descriptions.Item label="Count">{item?.count}</Descriptions.Item>
                  <Descriptions.Item label="Serial Numbers">{item?.scsrSerialNumbers?.map(scsr => `#${scsr?.[0]} `)}</Descriptions.Item>
                </Descriptions>)}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </>
    )
  );
};

const MinesInspectionRatioToFailureReport = ({
  reportName,
  report,
  from,
  to,
}) => {
  const getPassRate = (inspectionDetails) => {
    if (inspectionDetails?.totalItemsInspected === 0) return 0;
    return (
      (inspectionDetails?.passedInspections * 100) /
      inspectionDetails?.totalItemsInspected
    ).toPrecision(4);
  };

  const getRateGeneric = (divider, mainValue) => {
    if (divider === 0) return 0;
    return ((mainValue * 100) / divider).toPrecision(4);
  };

  return (
    reportName === "test3" && (
      <>
        <Descriptions bordered >
          <Descriptions.Item label="From">{from}</Descriptions.Item>
          <Descriptions.Item label="To">{to}</Descriptions.Item>
        </Descriptions>
        {report?.map((reportDetails) => (
          <>
            <div style={{ margin: "5px" }}>
              <h5>{`${reportDetails?._id} (${reportDetails?.totalInspections})`}</h5>
              <Descriptions column={4} bordered title={""}>
                <Descriptions.Item
                  style={{ fontWeight: "bold" }}
                  label={"Total Inspections Rounds"}
                >
                  {reportDetails?.totalInspections}
                </Descriptions.Item>

                <Descriptions.Item label={"Total SCSRs Inspected"}>
                  {reportDetails?.totalItemsInspected}
                </Descriptions.Item>
                <Descriptions.Item
                  style={{ fontWeight: "bold", color: "green" }}
                  label={"Inspection Pass Rate %"}
                >
                  {getPassRate(reportDetails)} %
                </Descriptions.Item>
                <Descriptions.Item
                  style={{ fontWeight: "bold", color: "red" }}
                  label={"Inspection Fail Rate %"}
                >
                  {getRateGeneric(
                    reportDetails?.totalItemsInspected,
                    reportDetails?.failedInspections
                  )}{" "}
                  %
                </Descriptions.Item>
                <Descriptions.Item
                  style={{ fontWeight: "bold", color: "red" }}
                  label={"Leak Test Fail Rate %"}
                >
                  {getRateGeneric(
                    reportDetails?.totalItemsInspected,
                    reportDetails?.failedLeakTestCount
                  )}{" "}
                  %
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={2} bordered title={""}>
                <Descriptions.Item label={"Visual Total Inspections Passed"}>
                  {reportDetails?.passedInspections}
                </Descriptions.Item>
                <Descriptions.Item label={"Visual Total Inspections Failed"}>
                  {reportDetails?.failedInspections}
                </Descriptions.Item>
                <Descriptions.Item label={"Visual Total Leaked Test Failed"}>
                  {reportDetails?.failedLeakTestCount}
                </Descriptions.Item>
                <Descriptions.Item label={"Total Not Available"}>
                  {reportDetails?.notAvailableCount}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </>
        ))}
      </>
    )
  );
};
