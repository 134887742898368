import { useState } from "react";
import { getObject } from "../services/storage.service";

export const useUser =(props)=>{

    const [user] = useState({...getObject("jwt_token")?.user});   
    const [jwtValueRow] = useState(getObject("jwt_token")?.jwt);
 
    return ({user,jwtValueRow})
    
}