import { Descriptions, Timeline } from 'antd'
import moment from 'moment'
import React from 'react'
import { SearchBarWithTimeRange } from './SearchBar'

export  function AutiTrailSCSR({loadscsrAuditTrail,scsrAuditTrai}) {
  return (
    <>
     
            <>
              <SearchBarWithTimeRange search={(e) => loadscsrAuditTrail(e,null)} />
              <>
                <Timeline mode="left">
                  {scsrAuditTrai?.map((autitrail) => (
                    <Timeline.Item
                      color={autitrail?.changes?.status==="active" ? "green":"red"}
                      label={moment(autitrail?.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    >
                      <p>
                        <Descriptions
                          column={1}
                          layout="horizontal"
                          title="Details"
                        >
                          <Descriptions.Item label="Modify By">
                          {autitrail?.modifiedBy
                            ? `${autitrail?.modifiedBy?.name} ${autitrail?.modifiedBy?.surname}`
                            : "-"}
                          </Descriptions.Item>
                         
                          <Descriptions.Item label="Status" >
                           
                           <div style={{fontWeight:"bold",color:autitrail?.newData?.status==="active" ? "green":"red"}}> {autitrail?.newData?.status?.toUpperCase()}</div>
                          </Descriptions.Item>
                          <Descriptions.Item label="Comments">
                            <p> {autitrail?.newData?.comment}</p>
                          </Descriptions.Item>
                        </Descriptions>
                      </p>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </>

            
              
            </>
         
    </>
  )
}

export  function AutiTrailInscpetions({loadscsrAuditTrail,scsrAuditTrai}) {
    return (
      <>
       
              <>
                <SearchBarWithTimeRange search={(e) => loadscsrAuditTrail(e,null)} />
                <>
                  <Timeline mode="left">
                    {scsrAuditTrai?.map((autitrail) => (
                      <Timeline.Item
                        color={autitrail?.changes?.status==="active" ? "green":"red"}
                        label={moment(autitrail?.createdAt).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      >
                        <p>
                          <Descriptions
                            column={1}
                            layout="horizontal"
                            title="Details"
                          >
                            <Descriptions.Item label="Modify By">
                            {autitrail?.modifiedBy
                              ? `${autitrail?.modifiedBy?.name} ${autitrail?.modifiedBy?.surname}`
                              : "-"}
                            </Descriptions.Item>
                           
                            <Descriptions.Item label="Status" >
                             
                             <div style={{fontWeight:"bold",color:autitrail?.newData?.status==="active" ? "green":"red"}}> {autitrail?.newData?.status?.toUpperCase()}</div>
                            </Descriptions.Item>
                            <Descriptions.Item label="Comments">
                              <p> {autitrail?.newData?.comment}</p>
                            </Descriptions.Item>
                          </Descriptions>
                        </p>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </>
  
              
                
              </>
           
      </>
    )
  }