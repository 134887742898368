
import React from 'react'
import { Button, Container } from 'react-bootstrap'
import Header from '../components/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Building, FileCheck, People } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../hooks/user.hook';
import { DisplayBasedOnRole } from '../components/DisplayBasedOnRole';

export default function MainMenu() {
  const navigate = useNavigate();
  const { user } = useUser();
  return (
    <>
      <Header />
      <Container>
        <div style={{ textAlign: "center", margin: "20px" }}><h3>Hi {user?.name} !</h3></div>

        {user?.mine && <div style={{ textAlign: "center", margin: "20px" }}><h2>{user?.mine?.name} </h2></div>}


        <div style={{ textAlign: "center", marginTop: "2%", maxWidth: "400px", position: "absolute", marginLeft: "auto", marginRight: "auto", left: 0, right: 0 }}>
          <DisplayBasedOnRole roles={["Admin", "Mine","OEM"]}>
            <Button onClick={() => navigate("/app/inspections")} variant='primary' style={{ width: "300px", height: "100px", margin: "10px", fontSize: "1.2rem" }}><FileCheck size={30} />  Inspections</Button>
          </DisplayBasedOnRole>
          <DisplayBasedOnRole roles={["Admin"]}>
            <Button onClick={() => navigate("/app/users")} style={{ width: "300px", height: "100px", margin: "10px", fontSize: "1.2rem" }}><People size={30} /> Users</Button>
          </DisplayBasedOnRole>
          <DisplayBasedOnRole roles={["Admin"]}>
            <Button onClick={() => navigate("/app/mines")} style={{ width: "300px", height: "100px", margin: "10px", fontSize: "1.2rem" }}><Building size={30} /> Mines</Button>
          </DisplayBasedOnRole>
          <DisplayBasedOnRole roles={["Admin", "Inspector", "Mine","Repairman","OEM"]}>
            <Button onClick={() => navigate("/app/scsr")} style={{ width: "300px", height: "100px", margin: "10px", fontSize: "1.2rem" }}><Building size={30} /> SCSRs</Button>
          </DisplayBasedOnRole>
          <DisplayBasedOnRole roles={["Admin", "Repairman","Inspector"]}>
            <Button onClick={() => navigate("/app/repairs")} style={{ width: "300px", height: "100px", margin: "10px", fontSize: "1.2rem" }}><Building size={30} /> RFQS ODERS</Button>
          </DisplayBasedOnRole>
        </div>

      </Container>
    </>


  )
}
